import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { CircularProgress, FormControl, MenuItem, Paper, Select } from '@mui/material';
// import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import NhlStatsContext from 'nhl/stats/NhlStatsContext';
import SeasonSelector from 'nhl/stats/SeasonSelector';
import DateChooser from 'nhl/stats/DateChooser';

import NhlMatchupsContext from 'nhl/stats/NhlMatchupsContext';

import NhlStatsEmptyNetGameLog from './game-log/NhlStatsEmptyNetGameLog';
import NhlStatsEmptyNetMatchups from './matchup/NhlStatsEmptyNetMatchups';
import NhlStatsEmptyNetTeamSummary from './NhlStatsEmptyNetTeamSummary';
// import NhlStatsEmptyNetDashboard from './NhlStatsEmptyNetDashboard';

import styles from './NhlStatsEmptyNet.module.scss';

const VIEWS = {
  MATCHUPS: 'matchups',
  GAME_LOG: 'game-log',
  TEAM_SUMMARY: 'team-summary',
};

const NhlStatsEmptyNet = (props) => {
  const { className } = props;
  const [searchParams] = useSearchParams();
  const { data, isFetching, fetchError } = useContext(NhlStatsContext);
  const { selectedSeason, setSelectedSeason } = useContext(NhlStatsContext);
  const { date, setDate } = useContext(NhlMatchupsContext);
  const [subView, setSubView] = useState(searchParams.get('subview') || VIEWS.MATCHUPS);

  let updatedAt = `Updated: ??`;
  let overlay = null;
  let overlayContent = null;

  if (isFetching) {
    overlayContent = (
      <>
        <CircularProgress />
        <div>Loading...</div>
      </>
    );
  }

  if (fetchError) {
    overlayContent = (
      <>
        <div>Unable to load data</div>
      </>
    );
  }

  if (overlayContent) {
    overlay = (
      <div className={cx(styles.overlay)}>
        <Paper className={styles.overlayContent}>{overlayContent}</Paper>
      </div>
    );
  }

  if (data?.updatedAt) {
    updatedAt = `Updated: ${data.updatedAt?.toLocaleString()}`;
  }

  const onSubViewChange = (event) => {
    const newSubView = event?.target?.value;
    if (!newSubView) return;
    setSubView(newSubView);
  };

  let subViewComponents = null;
  switch (subView) {
    case VIEWS.MATCHUPS:
      subViewComponents = [<NhlStatsEmptyNetMatchups key={`subView[${subView}]`} />];
      break;

    case VIEWS.GAME_LOG:
      // subViewComponents = [<NhlStatsEmptyNetDashboard key='en-dashboard' />, <NhlStatsEmptyNetGameLog key='en-log' />];
      subViewComponents = [<NhlStatsEmptyNetGameLog key={`subView[${subView}]`} />];
      break;

    case VIEWS.TEAM_SUMMARY:
      subViewComponents = [<NhlStatsEmptyNetTeamSummary key={`subView[${subView}]`} />];
      break;

    default:
      subViewComponents = [];
      break;
  }

  const renderSubViewHeaderControls = () => {
    switch (subView) {
      case VIEWS.MATCHUPS:
        return (
          <>
            <SeasonSelector selectedSeason={selectedSeason} onChange={setSelectedSeason} />
            <DateChooser selectedDate={date} onChange={setDate} />
          </>
        );

      case VIEWS.GAME_LOG:
        return <SeasonSelector selectedSeason={selectedSeason} onChange={setSelectedSeason} />;

      case VIEWS.TEAM_SUMMARY:
        return null;

      default:
        return null;
    }
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.header}>
        <FormControl size='small'>
          <Select variant='outlined' id='subview-select' classes={{ select: styles.subViewSelect }} value={subView} onChange={onSubViewChange}>
            {/* <MenuItem value={VIEWS.TEAM_SUMMARY}>Team Summary</MenuItem> */}
            <MenuItem value={VIEWS.MATCHUPS}>Matchups</MenuItem>
            <MenuItem value={VIEWS.GAME_LOG}>Log</MenuItem>
          </Select>
        </FormControl>
        {renderSubViewHeaderControls()}
      </div>
      <div className={styles.body}>
        {subViewComponents}
        {overlay}
      </div>
      <div className={styles.footer}>{updatedAt}</div>
    </div>
  );
};

NhlStatsEmptyNet.propTypes = {
  className: PropTypes.string,
};

export default NhlStatsEmptyNet;
