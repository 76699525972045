import _ from 'lodash';
import Utils from 'Utils';

const cloudFrontToken = `dklxbc86gvygh`;
// const cloudFrontToken = `d1wignjlnasfdp`; //test

// const BASE_URL = `https://ipl-sportsdata2--test.s3.amazonaws.com`;
const IPL_BASE_URL = `https://${cloudFrontToken}.cloudfront.net`;
const NHL_BASE_URL = `https://api-web.nhle.com/v1`;

class NhlMatchupsService {
  static fetchFullSchedule = async (season) => {
    let fullSchedule = {};
    {
      const url = new URL(`${IPL_BASE_URL}/nhl/${season}/schedule/all.json`);
      url.searchParams.set('t', Utils.dateToDayString(new Date()));
      const response = await fetch(url);
      const { entries } = await response.json();
      fullSchedule = entries;
    }

    return fullSchedule;
  };

  static fetchLandingForMatchup = async (id, fetchOptions) => {
    try {
      const url = new URL(`${NHL_BASE_URL}/gamecenter/${id}/landing`);
      const response = await Utils.fetchProxy(url, fetchOptions);
      return await response.json();
    } catch (e) {
      console.error(`[${id}] Unable to fetch basic data (right-rail)`, e);
    }

    return {};
  };

  static fetchRightRailForMatchup = async (id, fetchOptions) => {
    try {
      const url = new URL(`${NHL_BASE_URL}/gamecenter/${id}/right-rail`);
      const response = await Utils.fetchProxy(url, fetchOptions);
      return await response.json();
    } catch (e) {
      console.error(`[${id}] Unable to fetch basic data (right-rail)`, e);
    }

    return {};
  };

  static fetchBasicDataForMatchup = async (id, fetchOptions) => {
    try {
      let data = {};
      let promises = [];
      const loadLanding = async () => {
        const landing = await NhlMatchupsService.fetchLandingForMatchup(id, fetchOptions);
        data = _.merge(data, landing);
      };
      const loadRightRail = async () => {
        const rightRail = await NhlMatchupsService.fetchRightRailForMatchup(id, fetchOptions);
        data = _.merge(data, rightRail);
      };
      promises.push(loadLanding());
      promises.push(loadRightRail());
      await Promise.all(promises);
      return data;
    } catch (e) {
      console.error(`[${id}] Unable to fetch basic data (right-rail)`, e);
    }

    return {};
  };
}

export default NhlMatchupsService;
