import { GAME_STATE_GROUPS, GAME_STATE_TO_GROUP } from 'nhl/constants';
import NhlMatchupsService from './NhlMatchupsService';

const POLL_INTERVALS = { LIVE: 15 * 1000, PREMATCH: 60 * 1000 };

class NhlMatchup {
  controller = new AbortController();
  signal = this.controller.signal;

  id = null;
  info = {};

  data = {};
  dataLoaded = false;
  dataFetching = false;

  pollingEnabled = true;
  pollTimeout = null;
  onChangeHandler = null;

  constructor(matchupInfo) {
    const { id } = matchupInfo;
    this.id = id;
    this.info = matchupInfo;
    this.onLoad();
  }

  onLoad = async () => {
    this.dataLoaded = false;
    this.onChangeHandler?.();
    await this.fetchData();
    this.dataLoaded = true;
    this.onChangeHandler?.();
  };

  fetchData = async () => {
    if (!this.pollingEnabled) return;
    if (new Date() > new Date(this.info.date)) {
      this.dataFetching = true;
      this.onChangeHandler?.();
      this.data = await NhlMatchupsService.fetchBasicDataForMatchup(this.id, { signal: this.signal });
      this.dataFetching = false;
      this.onChangeHandler?.();
    }
    this.startPolling();
  };

  setOnChange = (onChangeHandler) => {
    this.onChangeHandler = onChangeHandler;
  };

  startPolling = async () => {
    const { gameState } = this.data;
    // console.log(`poll`, gameState);

    if (GAME_STATE_TO_GROUP[gameState] === GAME_STATE_GROUPS.LIVE) {
      this.pollTimeout = setTimeout(this.fetchData, POLL_INTERVALS.LIVE);
    } else if (GAME_STATE_TO_GROUP[gameState] === GAME_STATE_GROUPS.PREMATCH) {
      this.pollTimeout = setTimeout(this.fetchData, POLL_INTERVALS.PREMATCH);
    }
  };

  stopPolling = () => {
    this.controller.abort();
    clearTimeout(this.pollTimeout);
    this.pollingEnabled = false;
  };
}

export default NhlMatchup;
