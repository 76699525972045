import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Paper } from '@mui/material';

// import NhlStatsContext from './NhlStatsContext';
import NhlMatchupContext from 'nhl/stats/NhlMatchupContext';

import styles from './NhlStatsEmptyNetMatchup.module.scss';
import MatchupStatus from './MatchupStatus';
import { CircularProgress } from '@mui/material';
import NhlStatsContext from 'nhl/stats/NhlStatsContext';
import Utils from 'Utils';

const NUMBER_FORMATTER_PERC = new Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 0 });

const NhlStatsEmptyNetMatchup = (props) => {
  const { className } = props;

  const { data } = useContext(NhlStatsContext);
  const { matchup } = useContext(NhlMatchupContext);

  const enStatsThresholds = {
    any: { engPerc: { good: 0.52, bad: 0.42 }, medianStartTime: { good: 999, bad: 0 } },
    by1: { engPerc: { good: 0.45, bad: 0.35 }, medianStartTime: { good: 999, bad: 0 } },
    by2: { engPerc: { good: 0.6, bad: 0.5 }, medianStartTime: { good: 999, bad: 0 } },
  };

  if (!_.isEmpty(data?.leagueEmptyNet)) {
    const summary = data.leagueEmptyNet.summary.leading;
    for (const key of ['any', 'by1', 'by2']) {
      // enStatsThresholds[key].engPerc.good = summary[key].goalsForPerc + 0.05;
      // enStatsThresholds[key].engPerc.bad = summary[key].goalsForPerc - 0.05;
      enStatsThresholds[key].medianStartTime.good = summary[key].medianStartTime * 1.05;
      enStatsThresholds[key].medianStartTime.bad = summary[key].medianStartTime * 0.95;
    }
  }

  // console.log(`stats data`, data, enStatsThresholds);
  // console.log(`NhlStatsEmptyNetMatchup ${matchup.dataLoaded}`, matchup);
  const { id } = matchup.info;
  const { linescore, shotsByPeriod } = matchup.data;
  const { awayTeam, homeTeam } = matchup.data || {};
  const teams = { away: awayTeam, home: homeTeam };

  const renderShots = (shots) => {
    if (!_.isNumber(shots)) return null;
    return <div className={styles.shots}>[{shots}]</div>;
  };

  const renderTeamContent = (teamSide) => {
    const team = teams[teamSide];
    const teamCode = matchup.info.teams[teamSide]?.code;
    const score = team?.score;
    const shots = team?.sog;
    const scoreP1 = linescore?.byPeriod[0]?.[teamSide];
    const scoreP2 = linescore?.byPeriod[1]?.[teamSide];
    const scoreP3 = linescore?.byPeriod[2]?.[teamSide];
    const shotsP1 = shotsByPeriod?.[0]?.[teamSide];
    const shotsP2 = shotsByPeriod?.[1]?.[teamSide];
    const shotsP3 = shotsByPeriod?.[2]?.[teamSide];

    const renderTeamLinescore = () => {
      return (
        <div className={cx(styles.linescore, { [styles.contentLoading]: !matchup.dataLoaded })}>
          <div className={cx(styles.cell, styles.score)}>{score}</div>
          <div className={cx(styles.cell, styles.sogs)}>{renderShots(shots)}</div>

          <div className={cx(styles.cell, styles.p1)}>
            {scoreP1}
            {renderShots(shotsP1)}
          </div>
          <div className={cx(styles.cell, styles.p2)}>
            {scoreP2}
            {renderShots(shotsP2)}
          </div>
          <div className={cx(styles.cell, styles.p3)}>
            {scoreP3}
            {renderShots(shotsP3)}
          </div>
        </div>
      );
    };

    const renderTeamEmptyNetStatsSide = (leadingTrailing, teamSide, maskStats) => {
      const teamCode = matchup.info.teams[teamSide]?.code;
      const showTopLabels = teamSide === 'away';
      const showBottomLabels = teamSide === 'home';

      const teamData = data?.teams?.[teamCode];
      const teamSummary = teamData?.summary?.[leadingTrailing];

      const renderEnCell = (teamSummary, category) => {
        const enStats = teamSummary?.[category];
        if (!(enStats?.count > 0)) return <div className={styles.enStatsContentNoData}>(no data)</div>;

        const key = leadingTrailing === 'leading' ? 'goalsFor' : 'goalsAllowed';
        const perc = enStats[`${key}Perc`];
        const percLabel = NUMBER_FORMATTER_PERC.format(perc);
        const engCountLabel = `(${enStats[key]}/${enStats.count})`;
        const pullTimeLabel = Utils.formatTime(enStats.medianStartTime);

        const getEnStatsPercClassName = () => {
          if (perc < enStatsThresholds[category].engPerc.bad) return styles.enStatsBad;
          if (perc > enStatsThresholds[category].engPerc.good) return styles.enStatsGood;
          return styles.enStatsAverage;
        };
        return (
          <div className={styles.enStatsContent}>
            <div className={styles.enStatsContentTop}>
              <Paper className={cx(styles.enStatsPerc, getEnStatsPercClassName())}>{percLabel}</Paper>
            </div>
            <div className={styles.enStatsContentBottom}>
              <div>{pullTimeLabel}</div>
              <div>{engCountLabel}</div>
            </div>
          </div>
        );
      };

      return (
        <div className={cx(styles.enStats)}>
          <div className={styles.cell}>
            {renderEnCell(teamSummary, 'any')}
            {showTopLabels && <div className={cx(styles.cellFloating, styles.cellFloatingTop)}>any</div>}
          </div>
          <div className={styles.cell}>
            {renderEnCell(teamSummary, 'by1')}
            {showTopLabels && <div className={cx(styles.cellFloating, styles.cellFloatingTop)}>by1</div>}
          </div>
          <div className={styles.cell}>
            {renderEnCell(teamSummary, 'by2')}
            {showTopLabels && <div className={cx(styles.cellFloating, styles.cellFloatingTop)}>by2</div>}
          </div>
          {showBottomLabels && (
            <div className={cx(styles.cellFloating, styles.cellFloatingBottom)}>
              <div className={styles.middleLine} />
              <div className={styles.cellFloatingBottomLabel}>{leadingTrailing}</div>
            </div>
          )}
          <div className={cx(styles.enStatsMask, { [styles.enStatsMaskActive]: maskStats })}></div>
        </div>
      );
    };

    const renderTeamEmptyNetStats = (teamSide) => {
      const oppTeamSide = teamSide === 'home' ? 'away' : 'home';
      const teamScore = matchup.data?.[`${teamSide}Team`]?.score || 0;
      const oppTeamScore = matchup.data?.[`${oppTeamSide}Team`]?.score || 0;
      const isLeading = teamScore > oppTeamScore;
      const isTrailing = teamScore < oppTeamScore;

      return (
        <>
          <div className={cx(styles.cell, styles.columnSpacer)} />
          {renderTeamEmptyNetStatsSide('leading', teamSide, isTrailing)}
          <div className={cx(styles.cell, styles.columnSpacer)} />
          {renderTeamEmptyNetStatsSide('trailing', teamSide, isLeading)}
        </>
      );
    };

    return (
      <div className={styles.teamContainer}>
        <div className={cx(styles.cell, styles.teamLogo)}>
          <img className={styles.logo} src={`/assets/nhl/${teamCode}.svg`} alt='logo' />
        </div>
        <div className={cx(styles.cell, styles.team)}>{teamCode}</div>
        {renderTeamLinescore()}
        {renderTeamEmptyNetStats(teamSide)}
      </div>
    );
  };

  const renderLoadingOverlay = () => {
    if (matchup?.dataLoaded) return null;
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  };

  return (
    <div key={`matchup[${id}]`} className={cx(styles.container, className)}>
      <div className={styles.status}>
        <MatchupStatus gameData={matchup.data} />
      </div>
      <div className={cx(styles.content)}>
        {renderTeamContent('away')}
        <div className={styles.spacerRow} />
        {renderTeamContent('home')}
      </div>
      {renderLoadingOverlay()}
    </div>
  );
};

NhlStatsEmptyNetMatchup.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default NhlStatsEmptyNetMatchup;
