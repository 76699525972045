import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import { Button } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Teams from 'mlb/Teams';
import Stats from 'mlb/stats/Stats';

import styles from './MlbPppTeamLog.module.scss';

const MlbPppTeamLog = (props) => {
  const { className, teamCode } = props;

  const [teamLog, setTeamLog] = useState([]);
  const [teamLogPppTrailing, setTeamLogPppTrailing] = useState([]);
  const [teamLogPppLeading, setTeamLogPppLeading] = useState([]);
  useEffect(() => {
    (async () => {
      let data = Stats.data;
      if (!data.teamLogs[teamCode]) {
        data = await Stats.loadTeamLog(2023, teamCode);
      }
      const teamLog = data.teamLogs[teamCode];
      setTeamLog(teamLog);
      setTeamLogPppTrailing(_.filter(teamLog, { sits: { for: { ppp: { trailingChance: true } } } }));
      setTeamLogPppLeading(_.filter(teamLog, { sits: { for: { ppp: { leadingChance: true, leadingPpp: true } } } }));
    })();
  }, [teamCode]);

  const teamLogPpps = _.orderBy(_.concat(teamLogPppTrailing, teamLogPppLeading), 'matchup.date');
  const team = Teams.TEAMS_BY_CODE[teamCode];
  if (!team) return null;
  if (!teamLog) return null;
  const formatDate = (date) => {
    const opts = {
      month: '2-digit',
      day: '2-digit',
    };
    return date.toLocaleDateString(0, opts);
  }

  const renderEntry = (entry, key) => {
    const { matchup, sits } = entry;
    const matchupVs = matchup.homeAway === 'away' ? 'at' : 'vs';

    const { runDiff, player, inning, halfInning, leadingPpp } = sits.for.ppp;
    let inningLabel = ``;
    let early = false;
    let upDown = leadingPpp ? 'UP' : 'DOWN';
    let event = <>&mdash;&mdash;&mdash;&mdash;&mdash;</>;

    if (player.id) {
      inningLabel = `${halfInning.slice(0, 3)} ${inning}`;
      event = `${player.last} (${player.pos})`;

      if (matchup.homeAway === 'home' && inning <= 8) {
        early = true;
      } else if (matchup.homeAway === 'away' && inning <= 7) {
        early = true;
      }
    }

    const onOpenClick = () => {
      window.open(`https://www.mlb.com/gameday/${matchup.id}`, '_blank');
    }

    return (
      <div key={key} className={styles.entry}>
        <div className={styles.date}>
          {formatDate(new Date(matchup.date))}
        </div>
        <div className={styles.opponent}>
          <Button classes={{ root: styles.matchupButton }} size='small' onClick={onOpenClick}><span>{`${matchupVs} ${matchup.oppTeam}`}</span><OpenInNewIcon className={styles.matchupIcon} fontSize='inherit' /></Button>
        </div>
        <div className={cx(styles.situation, { [styles.situationLeading]: leadingPpp })}>
          {`${upDown} ${Math.abs(runDiff)}`}
        </div>
        <div className={cx(styles.inning, { [styles.inningEarly]: early })}>
          {`${inningLabel}`}
        </div>
        <div className={styles.event}>
          {event}
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.container, className)}>
      {/* <div className={styles.entriesContainer}>
        <div className={styles.entries}>
          {_.map(teamLogPppLeading, renderEntry)}
        </div>
      </div>
      <div className={styles.spacer} /> */}
      <div className={styles.entriesContainer}>
        <div className={styles.entries}>
          {_.map(teamLogPpps, renderEntry)}
        </div>
      </div>
    </div>
  );
};

MlbPppTeamLog.propTypes = {
  className: PropTypes.string,
  teamCode: PropTypes.string.isRequired,
};

export default React.memo(MlbPppTeamLog);
