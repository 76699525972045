import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { MenuItem, Select, ToggleButton, ToggleButtonGroup } from '@mui/material';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import Teams from 'mlb/Teams';

import GridUtils from './GridUtils';
import MlbStatsContext from './MlbStatsContext';
import styles from './MlbPppLog.module.scss';

const FILTERS = {
  MARGIN_SIDE: {
    KEY: 'marginSide',
    ANY: 'any',
    LEADING: 'leading',
    TRAILING: 'trailing',
  },
  TEAM: {
    KEY: 'team',
    ALL: 'all',
  },
};

const DEFAULT_FILTERS = {
  [FILTERS.MARGIN_SIDE.KEY]: FILTERS.MARGIN_SIDE.ANY,
  [FILTERS.TEAM.KEY]: FILTERS.TEAM.ALL,
};

// https://www.ag-grid.com/react-data-grid/column-properties/
const DEFAULT_COL_DEF = {
  // sortable: true,
  resizable: true,
  // filter: true,

  // suppressSizeToFit: true,
  // flex: 1,
  // floatingFilter: true,
  // cellDataType: false,
  cellClass: styles.gridBodyCell,
  headerClass: styles.gridHeaderCell,
  // groupsHeaderClass: styles.gridHeaderCell,
  // wrapText: true,
  // autoHeight: true,
  comparator: GridUtils.valueComparer,
};

const MlbPppLog = (props) => {
  const gridRef = useRef();
  const { data } = useContext(MlbStatsContext);
  const { teamLogs } = data;
  const [allRows, setAllRows] = useState(null);
  const [visibleRows, setVisibleRows] = useState(null);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });

  const grid = gridRef.current;

  const getColumnDefs = useCallback(() => {
    const columnDefs = [GridUtils.columnDefs.date, GridUtils.columnDefs.team, GridUtils.columnDefs.oppTeam, GridUtils.columnDefs.result, GridUtils.columnDefs.pppForGroup, GridUtils.columnDefs.pppAgainstGroup];
    return columnDefs;
  }, []);

  const [columnDefs] = useState(getColumnDefs());

  useEffect(() => {
    setAllRows(null);
    if (!_.isNil(teamLogs)) {
      let rows = [];
      for (const teamCode of _.keys(teamLogs)) {
        for (const game of teamLogs[teamCode]) {
          rows.push(game);
        }
      }
      rows = _.orderBy(rows, ['matchup.date', 'matchup.id'], ['desc', 'desc']);
      setAllRows(rows);
    }
    return () => {
      setAllRows(null);
    };
  }, [teamLogs]);

  useEffect(() => {
    if (_.isNil(allRows)) {
      setVisibleRows(allRows);
      return;
    }
    let visibleRows = [...allRows];
    if (filters.marginSide !== FILTERS.MARGIN_SIDE.ANY) {
      const isLeadingSide = filters.marginSide === FILTERS.MARGIN_SIDE.LEADING;
      if (isLeadingSide) {
        visibleRows = _.filter(visibleRows, (row) => {
          return row.sits.for.ppp.leadingChance === true;
        });
      } else {
        visibleRows = _.filter(visibleRows, (row) => {
          return row.sits.for.ppp.trailingChance === true;
        });
      }
    }
    if (filters[FILTERS.TEAM.KEY] !== FILTERS.TEAM.ALL) {
      visibleRows = _.filter(visibleRows, (row) => {
        return row.matchup.team === filters[FILTERS.TEAM.KEY];
      });
    }
    setVisibleRows(visibleRows);
  }, [allRows, filters, setVisibleRows]);

  const onGridReady = () => {};

  const onFirstDataRendered = useCallback(() => {}, []);

  const onFilterMarginSideChange = (event, newValue) => {
    if (!newValue) return;
    setFilters(_.merge({}, filters, { [FILTERS.MARGIN_SIDE.KEY]: newValue }));
  };
  const onFilterTeamChange = (newValue) => {
    setFilters(_.merge({}, filters, { [FILTERS.TEAM.KEY]: newValue }));
  };

  const onCellClicked = async (event) => {
    const gridApi = grid?.api;
    if (!gridApi) return;
    if (!_.includes(['team', 'oppTeam'], event?.colDef?.colId)) return;

    onFilterTeamChange(event.value?.teamCode);
  };

  const renderTeamOptions = () => {
    const teamOptions = _.map(Teams.TEAMS_BY_CODE, (team) => {
      const logo = <img className={styles.teamLogo} src={`/assets/mlb/logos/${team.code}.svg`} alt='logo' />;
      return (
        <MenuItem key={`teamOption[${team.code}]`} className={styles.teamOption} value={team.code}>
          <div className={styles.teamOptionContent}>
            {logo}
            <div className={styles.teamCode}>{team.code}</div>
          </div>
        </MenuItem>
      );
    });

    return (
      <Select
        variant='outlined'
        id='team-select'
        classes={{ select: styles.teamSelect }}
        value={filters.team}
        onChange={(event) => {
          onFilterTeamChange(event.target.value);
        }}>
        <MenuItem className={styles.teamOption} value={FILTERS.TEAM.ALL}>
          All Teams
        </MenuItem>
        {teamOptions}
      </Select>
    );
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.filtersContainer}>
        {renderTeamOptions()}
        <ToggleButtonGroup classes={{ grouped: styles.filterButtons }} size='small' color='primary' value={filters.marginSide} exclusive onChange={onFilterMarginSideChange}>
          <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN_SIDE.ANY}>
            Any
          </ToggleButton>
          <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN_SIDE.LEADING}>
            Leading
          </ToggleButton>
          <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN_SIDE.TRAILING}>
            Trailing
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={cx('ag-theme-balham', styles.gridContainer)}>
        <AgGridReact
          ref={gridRef}
          rowData={visibleRows}
          columnDefs={columnDefs}
          defaultColDef={DEFAULT_COL_DEF}
          animateRows={false}
          rowSelection='multiple'
          autoSizeStrategy={{ type: 'fitCellContents' }}
          suppressLoadingOverlay={true}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onCellClicked={onCellClicked}
          // debug
        />
      </div>
    </div>
  );
};

MlbPppLog.propTypes = {
  className: PropTypes.string,
};

export default MlbPppLog;
