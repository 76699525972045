import { GAME_STATE_TO_GROUP } from 'nhl/constants';

class MatchupUtil {
  static getTypeGroup = (gameState) => {
    const gameStateGroup = GAME_STATE_TO_GROUP[gameState];
    if (!gameStateGroup) return null;

    return gameStateGroup;
  };

  static formatStartTime = (dateTime) => {
    const startDate = new Date(dateTime);
    return Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(startDate);
  };
}

export default MatchupUtil;
