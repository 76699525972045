import _ from 'lodash';
import TEAMS from './teams.json';

class MlbTeams {
  static TEAMS_BY_ID = {};
  // TEAMS_BY_CITY = {};
  static TEAMS_BY_CODE = {};
}

const sortedTeams = _.orderBy(TEAMS, 'code');

_.forEach(sortedTeams, (team) => {
  MlbTeams.TEAMS_BY_ID[team.id] = team;
  // MlbTeams.TEAMS_BY_CITY[team.city] = team;
  MlbTeams.TEAMS_BY_CODE[team.code] = team;
});

export default MlbTeams;