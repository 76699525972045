import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Paper } from '@mui/material';

import styles from './NhlGameTracker.module.scss';

const USE_TEST_DATA = false;
const BUCKET_SUFFIX = USE_TEST_DATA ? `--test` : ``;

const DT_FORMATTER = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'America/Los_Angeles' });
const dateToDayString = (date, sep = '-') => {
  if (!date) return '';
  const dateParts = {};
  _.forEach(DT_FORMATTER.formatToParts(date), (datePart) => {
    dateParts[datePart.type] = datePart.value;
  });
  return `${dateParts.year}${sep}${String(dateParts.month).padStart(2, 0)}${sep}${String(dateParts.day).padStart(2, 0)}`;
};


const NhlGameTracker = (props) => {
  const [events, setEvents] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      let day = dateToDayString(new Date());
      const customDay = searchParams.get('day');
      console.log(searchParams);
      console.log(customDay);
      if (customDay) {
        day = customDay;
      }
      const response = await fetch(`https://ipl-sportsdata2${BUCKET_SUFFIX}.s3.amazonaws.com/whe/ice-hockey/day/${day}.json`);
      const eventsById = await response.json();
      let events = _.orderBy(_.values(eventsById), 'eventTime');
      events = _.filter(events, { leagueId: 'nhl' });
      setEvents(events);
    })();
  }, [searchParams]);

  const renderEvents = () => {
    if (_.isEmpty(events)) return null;
    return (
      <div className={styles.gameTrackers}>
        {_.map(events, (event) => {
          // const { scoreboardUrl, eventTime, awayTeam, homeTeam } = event;
          const { eventId, scoreboardUrl } = event;
          if (!scoreboardUrl) return null;

          return <iframe key={`gametracker[${eventId}]`} className={styles.gameTrackerIframe} src={scoreboardUrl} title={`gametracker[${eventId}]`} />;
        })}
      </div>
    );
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          NHL Game Trackers
        </h1>
      </div>
      <Paper className={styles.gameTrackersContainer}>
        {renderEvents()}
      </Paper>
    </div>
  );
};

NhlGameTracker.propTypes = {
  className: PropTypes.string,
};

export default React.memo(NhlGameTracker);
