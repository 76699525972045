import React from 'react';
import PropTypes from 'prop-types';
// import { Button } from '@mui/material';

import styles from './Home.module.scss';

export default class Home extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    return (
      <div className={styles.home}>
        👀👀👀
        {/* <Button classes={{ root: styles.item }} variant='contained'>
          AHL
        </Button> */}
      </div>
    );
  }
}
