import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import NhlStatsContext from './NhlStatsContext';
import Utils from 'Utils';

const cloudFrontToken = `dklxbc86gvygh`;
// const cloudFrontToken = `d1wignjlnasfdp`; //test

// const BASE_URL = `https://ipl-sportsdata2--test.s3.amazonaws.com`;
const BASE_URL = `https://${cloudFrontToken}.cloudfront.net`;

const fetchStats = async (season) => {
  const data = {};
  const teamResults = {};
  const teamRosters = {};
  {
    const url = new URL(`${BASE_URL}/nhl/${season}/results/all.json`);
    url.searchParams.set('t', Utils.dateToDayString(new Date()));
    const response = await fetch(url);
    const { entries } = await response.json();
    let _entries = _.orderBy(_.values(entries), ['game.date', 'game.id'], ['desc', 'desc']);
    for (const entry of _entries) {
      const { id, teams, result, gameCenterLink, roster } = entry;
      const awayTeam = teams.away.code;
      const homeTeam = teams.home.code;
      _.set(teamResults, [awayTeam, String(id)], {
        goalsFor: result.away.ft.goalsFor,
        goalsAllowed: result.away.ft.goalsAgainst,
        overtime: result.overtime,
        shootout: result.shootout,
        gameCenterPath: gameCenterLink,
      });
      _.set(teamResults, [homeTeam, String(id)], {
        goalsFor: result.home.ft.goalsFor,
        goalsAllowed: result.home.ft.goalsAgainst,
        overtime: result.overtime,
        shootout: result.shootout,
        gameCenterPath: gameCenterLink,
      });

      _.set(teamRosters, [awayTeam, String(id)], roster?.away);
      _.set(teamRosters, [homeTeam, String(id)], roster?.home);
    }
    // console.log(teamRosters);
  }
  data.teamResults = teamResults;

  let teamData = {};
  {
    const url = new URL(`${BASE_URL}/nhl/${season}/empty-net/all-teams.json`);
    url.searchParams.set('t', Utils.dateToDayString(new Date()));
    const response = await fetch(url);
    const { league, teams, updatedAt } = await response.json();
    data.leagueEmptyNet = league;
    teamData = _.merge({}, teams);
    // augment EN data with some basic result info
    for (const team of _.values(teamData)) {
      for (const entry of _.values(team.entries)) {
        const { game, teamCode } = entry;
        entry.result = teamResults?.[teamCode]?.[game.id];
        entry.roster = teamRosters?.[teamCode]?.[game.id];
      }
    }
    data.updatedAt = new Date(updatedAt);
  }
  data.teams = teamData;

  let enAll = {};
  {
    const url = new URL(`${BASE_URL}/nhl/${season}/empty-net/all.json`);
    url.searchParams.set('t', Utils.dateToDayString(new Date()));
    const response = await fetch(url);
    const { entries } = await response.json();
    enAll = entries;
  }
  data.enAll = enAll;

  return data;
};
const NhlStatsProvider = (props) => {
  const { children } = props;

  const [selectedSeason, setSelectedSeason] = useState(2024);
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setIsFetching(true);
    setFetchError(null);
    const loader = async () => {
      try {
        const data = await fetchStats(selectedSeason);
        setData(data);
      } catch (e) {
        setData({});
        setFetchError(`Unable to load data`);
        console.error(`Unable to load data`, e);
      }

      setIsFetching(false);
    };
    loader();
    return () => {
      setData({});
      setIsFetching(false);
    };
  }, [selectedSeason]);

  const contextValue = {
    fetchError,
    isFetching,
    data,
    selectedSeason,
    setSelectedSeason,
  };

  return <NhlStatsContext.Provider value={contextValue}>{children}</NhlStatsContext.Provider>;
};

NhlStatsProvider.propTypes = {};

export default NhlStatsProvider;
