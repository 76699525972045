import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Page from 'common/Page';
import NhlMatchupsProvider from 'nhl/stats/NhlMatchupsProvider';
import NhlStatsProvider from 'nhl/stats/NhlStatsProvider';

import NhlStatsEmptyNet from './NhlStatsEmptyNet';

import styles from './NhlStatsEmptyNetPage.module.scss';

const NhlStatsEmptyNetPage = (props) => {
  const headerContent = null;
  return (
    <Page className={cx(styles.container, props.className)} title={'NHL EN Stats'} headerContent={headerContent}>
      <NhlMatchupsProvider>
        <NhlStatsProvider>
          <NhlStatsEmptyNet />
        </NhlStatsProvider>
      </NhlMatchupsProvider>
    </Page>
  );
};

NhlStatsEmptyNetPage.propTypes = {
  className: PropTypes.string,
};

export default NhlStatsEmptyNetPage;
