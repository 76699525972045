import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, MenuItem, Select } from '@mui/material';

import styles from './SeasonSelector.module.scss';

const SeasonSelector = (props) => {
  const { selectedSeason, onChange } = props;
  return (
    <FormControl size='small'>
      <Select
        variant='outlined'
        id='season-select'
        classes={{ select: styles.seasonSelect }}
        value={selectedSeason}
        onChange={(event) => {
          onChange(event.target.value);
        }}>
        {/* <MenuItem value={2019}>2019</MenuItem>
        <MenuItem value={2020}>2020</MenuItem>
        <MenuItem value={2021}>2021</MenuItem>
        <MenuItem value={2022}>2022</MenuItem> */}
        <MenuItem value={2023}>2023</MenuItem>
        <MenuItem value={2024}>2024</MenuItem>
      </Select>
    </FormControl>
  );
};

SeasonSelector.propTypes = {
  selectedSeason: PropTypes.any,
  onChange: PropTypes.func,
};

export default SeasonSelector;
