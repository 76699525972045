import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import { Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Teams from 'mlb/Teams';
import Stats from 'mlb/stats/Stats';
import MlbPppTeamLog from './MlbPppTeamLog';

import styles from './MlbPppTeam.module.scss';

const MlbPppTeam = (props) => {
  const { className, cellClassName, teamCode } = props;
  const { summaryByTeam } = Stats.data;

  const [isExpanded, setIsExpanded] = useState(false);
  const [teamData, setTeamData] = useState({});
  const [pppData, setPppData] = useState({});

  useEffect(() => {
    const data = summaryByTeam?.[teamCode];
    setTeamData(data);
  }, [summaryByTeam, teamCode]);

  useEffect(() => {
    const data = {};
    const teamLog = Stats.data.teamLogs[teamCode];
    const trailingAllChances = _.filter(teamLog, `sits.for.ppp.trailingChance`);
    const trailingAllPpps = _.filter(teamLog, `sits.for.ppp.trailingPpp`);
    const trailingGroupedChances = _.countBy(trailingAllChances, (item) => Math.max(item.sits.for.ppp.runDiff, -9));
    const trailingGroupedPpps = _.countBy(trailingAllPpps, (item) => Math.max(item.sits.for.ppp.runDiff, -9));
    const trailingEarlyPppsHome = _.filter(trailingAllPpps, (item) => { return item.matchup.homeAway === 'home' && item.sits.for.ppp.inning <= 8 });
    const trailingEarlyPppsAway = _.filter(trailingAllPpps, (item) => { return item.matchup.homeAway === 'away' && item.sits.for.ppp.inning <= 7 });
    const leadingAllChances = _.filter(teamLog, `sits.for.ppp.leadingChance`);
    const leadingAllPpps = _.filter(teamLog, `sits.for.ppp.leadingPpp`);
    data.pppFor = {
      trailingAll: {
        chances: trailingAllChances.length,
        ppps: trailingAllPpps.length,
      },
      trailing6: {
        chances: trailingGroupedChances[-6] || 0,
        ppps: trailingGroupedPpps[-6] || 0,
      },
      trailing7: {
        chances: trailingGroupedChances[-7] || 0,
        ppps: trailingGroupedPpps[-7] || 0,
      },
      trailing8: {
        chances: trailingGroupedChances[-8] || 0,
        ppps: trailingGroupedPpps[-8] || 0,
      },
      trailing9p: {
        chances: trailingGroupedChances[-9] || 0,
        ppps: trailingGroupedPpps[-9] || 0,
      },
      trailingEarly: {
        chances: null,
        ppps: trailingEarlyPppsHome.length + trailingEarlyPppsAway.length,
      },
      leadingAll: {
        chances: leadingAllChances.length,
        ppps: leadingAllPpps.length,
      },
    };
    setPppData(data);
  }, [teamCode]);

  const team = Teams.TEAMS_BY_CODE[teamCode];
  if (!team) return null;
  if (!summaryByTeam) return null;
  if (_.isEmpty(teamData)) return null;
  if (_.isEmpty(pppData)) return null;

  const renderBody = () => {
    if (!isExpanded) return null;
    return (
      <div className={styles.teamBody}>
        <MlbPppTeamLog key={team.code} teamCode={team.code} />
      </div>
    );
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // const pppFor = teamData.stats.for.ppp;
  return (
    // <div key={team.code} data-team-code={team.code} className={cx(styles.container, className)}>
    <>
      <Button classes={{ root: cx(styles.container, styles.teamHeader, className) }} onClick={toggleExpanded}>
        <div className={cx(styles.cell, cellClassName, styles.teamHeaderLeft)}><div className={styles.teamCode}>{team.code}</div></div>
        <div className={cx(styles.cell, cellClassName, styles.primaryStatCell)}>{`${pppData.pppFor.trailingAll.ppps} / ${pppData.pppFor.trailingAll.chances}`}</div>
        <div className={cx(styles.cell, cellClassName)}>{`${pppData.pppFor.trailing6.ppps}/${pppData.pppFor.trailing6.chances}`}</div>
        <div className={cx(styles.cell, cellClassName)}>{`${pppData.pppFor.trailing7.ppps}/${pppData.pppFor.trailing7.chances}`}</div>
        <div className={cx(styles.cell, cellClassName)}>{`${pppData.pppFor.trailing8.ppps}/${pppData.pppFor.trailing8.chances}`}</div>
        <div className={cx(styles.cell, cellClassName)}>{`${pppData.pppFor.trailing9p.ppps}/${pppData.pppFor.trailing9p.chances}`}</div>
        <div className={cx(styles.cell, cellClassName)}>{`${pppData.pppFor.trailingEarly.ppps}x`}</div>
        <div className={cx(styles.cell, cellClassName)}>{`${pppData.pppFor.leadingAll.ppps}x`}</div>
        <div className={cx(styles.cell, cellClassName, styles.teamHeaderRight)}>
          {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
      </Button>
      {renderBody()}
    </>
    // </div>
  );
};

MlbPppTeam.propTypes = {
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  teamCode: PropTypes.string.isRequired,
};

export default React.memo(MlbPppTeam);
