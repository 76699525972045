import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Button, Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import NhlStatsContext from './NhlStatsContext';
import NhlMatchupsContext from 'nhl/stats/NhlMatchupsContext';
import NhlMatchupProvider from 'nhl/stats/NhlMatchupProvider';
import NhlStatsEmptyNetGameLog from 'nhl/stats/empty-net/game-log/NhlStatsEmptyNetGameLog';

import NhlStatsEmptyNetMatchup from './NhlStatsEmptyNetMatchup';

import styles from './NhlStatsEmptyNetMatchups.module.scss';
// import Teams from 'nhl/Teams';

const NhlStatsEmptyNetMatchups = (props) => {
  const [selectedMatchupId, setSelectedMatchupId] = useState(null);
  const { matchups, date } = useContext(NhlMatchupsContext);

  useEffect(() => {
    setSelectedMatchupId(null);
  }, [date]);

  const renderMatchup = (matchup) => {
    const { id } = matchup;
    return (
      <Paper key={`matchup[${id}]`} className={styles.matchupContainer}>
        <Button
          className={styles.matchupButton}
          onClick={() => {
            setSelectedMatchupId(id);
          }}>
          <NhlMatchupProvider matchup={matchup}>
            <NhlStatsEmptyNetMatchup className={styles.matchup} />
          </NhlMatchupProvider>
        </Button>
      </Paper>
    );
  };

  const renderMatchups = (matchups) => {
    // console.log(`renderMatchups`, matchups);
    const sortedMatchups = _.orderBy(matchups, ['info.date']);
    return (
      <div key={`matchups`} className={styles.matchups}>
        {_.map(sortedMatchups, renderMatchup)}
      </div>
    );
  };

  const renderAllMatchupsButton = () => {
    return (
      <Button
        variant='contained'
        className={styles.allMatchupsButton}
        key={`matchups[all]`}
        onClick={() => {
          setSelectedMatchupId(null);
        }}>
        <ArrowBackIcon />
        All Matchups
      </Button>
    );
  };

  const renderContent = () => {
    const selectedMatchup = _.find(matchups, { id: selectedMatchupId });
    let content = [];
    if (selectedMatchup) {
      const { id, info, data } = selectedMatchup;
      const { teams } = info;
      const { away, home } = teams;
      const awayTeamScore = data?.awayTeam?.score;
      const homeTeamScore = data?.homeTeam?.score;
      let awayMarginSide = null;
      let homeMarginSide = null;
      if (awayTeamScore !== homeTeamScore) {
        awayMarginSide = awayTeamScore > homeTeamScore ? NhlStatsEmptyNetGameLog.FILTERS.MARGIN_SIDE.LEADING : NhlStatsEmptyNetGameLog.FILTERS.MARGIN_SIDE.TRAILING;
        homeMarginSide = homeTeamScore > awayTeamScore ? NhlStatsEmptyNetGameLog.FILTERS.MARGIN_SIDE.LEADING : NhlStatsEmptyNetGameLog.FILTERS.MARGIN_SIDE.TRAILING;
      }
      content.push(renderAllMatchupsButton());
      content.push(renderMatchups([selectedMatchup]));
      content.push(<NhlStatsEmptyNetGameLog key={`matchup[${id}]away-en-log`} className={styles.selectMatchupLog} selectedTeamCode={away.code} marginSide={awayMarginSide} />);
      content.push(<NhlStatsEmptyNetGameLog key={`matchup[${id}]home-en-log`} className={styles.selectMatchupLog} selectedTeamCode={home.code} marginSide={homeMarginSide} />);
    } else {
      content.push(renderMatchups(matchups));
    }
    return content;
  };

  return <div className={cx(styles.container, props.className)}>{renderContent()}</div>;
};

NhlStatsEmptyNetMatchups.propTypes = {
  className: PropTypes.string,
};

export default NhlStatsEmptyNetMatchups;
