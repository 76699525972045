import _ from 'lodash';

class GridUtils {
  static formatTime = (seconds) => {
    const m = Math.trunc(seconds / 60);
    const s = Math.trunc(seconds % 60);
    return `${m}:${_.padStart(s, 2, '0')}`;
  };

  static valueComparer = (valueA, valueB, nodeA, nodeB, isDescending) => {
    if (_.isNil(valueA)) return isDescending ? -1 : 1;
    if (_.isNil(valueB)) return isDescending ? 1 : -1;
    if (valueA === valueB) return 0;
    return valueA > valueB ? 1 : -1;
  };

  static getValueObjectComparer = (field) => {
    return (valueA, valueB, ...args) => {
      return GridUtils.valueComparer(valueA?.[field], valueB?.[field], ...args);
    };
  };

  static median = (values) => {
    let _values = _.compact(values);
    if (_.isEmpty(_values)) return 0;
    _values.sort(function (a, b) {
      return a - b;
    });

    let half = Math.floor(_values.length / 2);

    if (_values.length % 2) return _values[half];

    return Math.floor((_values[half - 1] + _values[half]) / 2);
  };

  static calcPercentage = (value, max, precision = 2) => {
    if (!value) return 0;
    if (!max) return 0;
    return _.floor(value / max, precision);
  };
}

export default GridUtils;
