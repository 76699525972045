import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { MenuItem, Paper, Select, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import NhlStatsContext from 'nhl/stats/NhlStatsContext';
import GridUtils from 'nhl/stats/GridUtils';

import styles from './NhlStatsEmptyNetGameLog.module.scss';
import Teams from 'nhl/Teams';

export const FILTERS = {
  MARGIN_SIDE: {
    KEY: 'marginSide',
    ANY: 'any',
    LEADING: 'leading',
    TRAILING: 'trailing',
  },
  MARGIN: {
    KEY: 'margin',
    ANY: 'any',
    BY1: 'by1',
    BY2: 'by2',
    BY3: 'by3',
  },
  TEAM: {
    KEY: 'team',
    ALL: 'all',
  },
};

const DEFAULT_FILTERS = {
  [FILTERS.MARGIN_SIDE.KEY]: FILTERS.MARGIN_SIDE.ANY,
  [FILTERS.MARGIN.KEY]: FILTERS.MARGIN.ANY,
  [FILTERS.TEAM.KEY]: FILTERS.TEAM.ALL,
};

const resultCellRenderer = ({ value }) => {
  if (_.isEmpty(value)) return null;
  const { goalsFor, goalsAllowed, overtime, shootout, gameCenterPath } = value;
  const isWinner = goalsFor > goalsAllowed;
  const winLoss = <div className={cx(styles.winLossTag, { [styles.isWinner]: isWinner })}>{isWinner ? 'W' : 'L'}</div>;
  let suffix = '';
  const icon = null; // TODO: popout icon
  if (overtime) suffix = 'OT';
  if (shootout) suffix = 'SO';
  return (
    <div className={cx(styles.gridBodyCell)}>
      {winLoss}
      <div
        className={styles.score}
        onClick={() => {
          // "/gamecenter/nsh-vs-tbl/2023/10/10/2023020001"
          const url = new URL(`https://www.nhl.com`);
          url.pathname = gameCenterPath;
          console.log(`zzz url`, url);
          window.open(url.toString(), `NHL[${gameCenterPath}]`);
        }}>
        {goalsFor}&ndash;{goalsAllowed} {suffix} {icon}
      </div>
    </div>
  );
};

const getTeamCellValueGetter = (teamField, homeValue) => {
  return ({ data }) => {
    const teamCode = data?.[teamField];
    const isHome = data?.homeAway === homeValue;
    return { teamCode, isHome };
  };
};

const teamCellRenderer = ({ value }) => {
  if (_.isEmpty(value)) return null;
  const { teamCode, isHome } = value;
  const atSymbol = isHome ? '@' : '';
  const logo = <img className={styles.teamLogo} src={`/assets/nhl/${teamCode}.svg`} alt='logo' />;
  const teamName = <div className={styles.teamName}>{`${atSymbol}${teamCode}`}</div>;
  return (
    <div className={cx(styles.gridBodyCell, styles.teamNameCell)}>
      {teamName}
      {logo}
    </div>
  );
};

const getEnInfoCellValueGetter = (enField, leadingTrailingField) => {
  return ({ data }) => {
    const enData = data?.[enField]?.[leadingTrailingField];
    if (_.isNil(enData?.start)) return null;
    return enData;
  };
};

const enInfoCellRenderer = ({ value }) => {
  if (!value?.start) return null;
  const { start, duration } = value;
  return (
    <div className={cx(styles.gridBodyCell)}>
      {GridUtils.formatTime(start)} rem (for {GridUtils.formatTime(duration)})
    </div>
  );
};

const getEnOutcomeCellValueGetter = (enField, leadingTrailingField, goalsForField, goalsAllowedField) => {
  return ({ data }) => {
    const enData = data?.[enField]?.[leadingTrailingField];
    // const goalsFor = enData?.[goalsForField];
    // const goalsAllowed = enData?.[goalsAllowedField];

    if (_.isNil(enData?.start)) return null;
    const { enGoals, goals } = enData;

    return { enGoals, goals };
  };
};

const enOutcomeCellRenderer = ({ value }) => {
  if (_.isNil(value)) return null;
  const { enGoals, goals } = value;

  let classes = [styles.outcomeLabel];
  let label = 'NG';
  let tooltipText = 'No Goals during situation';

  if (enGoals >= 1) {
    classes.push(styles.outcomeLabelENG);
    label = 'ENG';
    tooltipText = `Empty Net Goal Scored`;
  } else if (goals >= 1) {
    classes.push(styles.outcomeLabelCBG);
    label = 'CBG';
    tooltipText = `Comeback Goal Scored`;
  }

  if (goals + enGoals >= 2) {
    label += `+`;
    tooltipText = (
      <>
        {`${enGoals}x Empty Net Goals`}
        <br />
        {`${goals}x Comeback Goals`}
      </>
    );
  }

  return (
    <Tooltip title={tooltipText} placement='top' arrow>
      <div className={cx(classes)}>{label}</div>
    </Tooltip>
  );
};

const enMarginCellValueGetter = ({ data }) => {
  let margin = data.en.leading.margin;
  if (_.isNil(margin)) {
    margin = data.en.trailing.margin;
    if (margin > 0) margin = margin * -1;
  }
  if (_.isNil(margin)) return null;
  return margin;
};

const enOutcomeComparer = (valueA, valueB, ...args) => {
  let _valueA = null;
  let _valueB = null;

  if (!_.isEmpty(valueA)) {
    _valueA = valueA?.enGoals * 10 + valueA?.goals;
  }

  if (!_.isEmpty(valueB)) {
    _valueB = valueB?.enGoals * 10 + valueB?.goals;
  }

  return GridUtils.valueComparer(_valueA, _valueB, ...args);
};

const COLUMN_DEFS = {
  date: {
    colId: 'date',
    field: 'game.day',
    headerName: 'Date',
    cellDataType: 'dateString',
    sort: 'desc',
  },
  // {
  //   field: 'homeAway',
  //   headerName: 'Home?',
  // },
  headCoach: {
    colId: 'headCoach',
    headerName: 'Head Coach',
    field: 'roster.coaches.HC.fullName',
    cellDataType: 'text',
    // hide: true,
  },
  team: {
    colId: 'team',
    headerName: 'Team',
    valueGetter: getTeamCellValueGetter('teamCode', 'home'),
    filterValueGetter: 'data.teamCode',
    cellRenderer: teamCellRenderer,
    cellClass: styles.primaryTeamCell,
    comparator: GridUtils.getValueObjectComparer('teamCode'),
    cellDataType: 'object',
  },
  oppTeam: {
    colId: 'oppTeam',
    headerName: 'Opp',
    valueGetter: getTeamCellValueGetter('oppTeamCode', 'away'),
    filterValueGetter: 'data.oppTeamCode',
    cellRenderer: teamCellRenderer,
    comparator: GridUtils.getValueObjectComparer('teamCode'),
    cellDataType: 'object',
  },
  result: {
    colId: 'result',
    headerName: 'Result',
    field: 'result',
    // valueGetter: scoreCellValueGetter
    cellRenderer: resultCellRenderer,
    cellDataType: 'object',
  },
  margin: {
    colId: 'margin',
    headerName: `EN Margin`,
    cellDataType: 'number',
    valueGetter: enMarginCellValueGetter,
  },
  enLeading: {
    groupId: 'enLeading',
    headerName: 'Leading (Against EN)',
    headerClass: styles.gridHeaderCell,
    marryChildren: true,
    flex: 1,
    children: [
      {
        colId: 'leading-first',
        headerName: 'First Pull?',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: getEnInfoCellValueGetter('en', 'leading'),
        cellRenderer: enInfoCellRenderer,
        comparator: GridUtils.getValueObjectComparer('start'),
        cellDataType: 'object',
      },
      {
        colId: 'leading-firstOutcome',
        headerName: 'Outcome',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: getEnOutcomeCellValueGetter('en', 'leading'),
        cellRenderer: enOutcomeCellRenderer,
        comparator: enOutcomeComparer,
        cellDataType: 'object',
      },
      {
        colId: 'leading-extra',
        headerName: 'Repull?',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: getEnInfoCellValueGetter('enExtra', 'leading'),
        cellRenderer: enInfoCellRenderer,
        comparator: GridUtils.getValueObjectComparer('start'),
        cellDataType: 'object',
      },
      {
        colId: 'leading-extraOutcome',
        headerName: 'Outcome',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: getEnOutcomeCellValueGetter('enExtra', 'leading', 'enGoals', 'goals'),
        cellRenderer: enOutcomeCellRenderer,
        comparator: enOutcomeComparer,
        cellDataType: 'object',
      },
    ],
  },
  enTrailing: {
    groupId: 'enTrailing',
    headerName: 'Trailing (While EN)',
    headerClass: styles.gridHeaderCell,
    marryChildren: true,
    children: [
      {
        colId: 'trailing-first',
        headerName: 'First Pull?',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: getEnInfoCellValueGetter('en', 'trailing'),
        cellRenderer: enInfoCellRenderer,
        comparator: GridUtils.getValueObjectComparer('start'),
        cellDataType: 'object',
      },
      {
        colId: 'trailing-firstOutcome',
        headerName: 'Outcome',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: getEnOutcomeCellValueGetter('en', 'trailing', 'goals', 'enGoals'),
        cellRenderer: enOutcomeCellRenderer,
        comparator: enOutcomeComparer,
        cellDataType: 'object',
      },
      {
        colId: 'trailing-extra',
        headerName: 'Repull?',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: getEnInfoCellValueGetter('enExtra', 'trailing'),
        cellRenderer: enInfoCellRenderer,
        comparator: GridUtils.getValueObjectComparer('start'),
        cellDataType: 'object',
      },
      {
        colId: 'trailing-extraOutcome',
        headerName: 'Outcome',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: getEnOutcomeCellValueGetter('enExtra', 'trailing', 'goals', 'enGoals'),
        cellRenderer: enOutcomeCellRenderer,
        comparator: enOutcomeComparer,
        cellDataType: 'object',
      },
    ],
  },
};

const NhlStatsEmptyNetGameLog = (props) => {
  const { selectedTeamCode, marginSide } = props;
  const gridRef = useRef();
  const { data } = useContext(NhlStatsContext);
  const { teams } = data;
  const [allRows, setAllRows] = useState(null);

  const defaultFilters = { ...DEFAULT_FILTERS };
  if (selectedTeamCode) {
    defaultFilters[FILTERS.TEAM.KEY] = selectedTeamCode;
  }
  if (marginSide) {
    defaultFilters[FILTERS.MARGIN_SIDE.KEY] = marginSide;
  }
  const [filters, setFilters] = useState(defaultFilters);
  // const leadingTrailing = filters.marginSide;

  const grid = gridRef.current;

  const getColumnDefs = useCallback(() => {
    const columnDefs = [COLUMN_DEFS.date, COLUMN_DEFS.team, COLUMN_DEFS.oppTeam, COLUMN_DEFS.result, COLUMN_DEFS.margin, COLUMN_DEFS.enLeading, COLUMN_DEFS.enTrailing, COLUMN_DEFS.headCoach];
    // if (filters.marginSide === FILTERS.MARGIN_SIDE.ANY) {
    //   COLUMN_DEFS.enLeading.hide = false;
    //   COLUMN_DEFS.enTrailing.hide = false;
    // } else {
    //   COLUMN_DEFS.enLeading.hide = true;
    //   COLUMN_DEFS.enTrailing.hide = true;
    //   // columnDefs.push({ ...COLUMN_DEFS.enLeading, hide: leadingTrailing !== 'leading' });
    //   // columnDefs.push({ ...COLUMN_DEFS.enTrailing, hide: leadingTrailing !== 'trailing' });
    // }
    // switch (leadingTrailing) {
    //   case 'leading':
    //     columnDefs.push(COLUMN_DEFS.enLeading);
    //     break;
    //     case 'trailing':
    //     columnDefs.push(COLUMN_DEFS.enTrailing);
    //     break;
    // }
    return columnDefs;
  }, []);

  const [columnDefs] = useState(getColumnDefs());

  useEffect(() => {
    let rows = [];
    for (const team of _.values(teams)) {
      for (const entry of _.values(team.entries)) {
        rows.push(entry);
      }
    }
    rows = _.orderBy(rows, ['game.date', 'game.id'], ['desc', 'desc']);
    setAllRows(rows);
    return () => {
      setAllRows(null);
    };
  }, [teams]);

  useEffect(() => {
    const gridApi = grid?.api;
    if (!gridApi) return;
    // console.log(`zzz gridApi`, gridApi);
    (async () => {
      const columnDefs = gridApi.getColumnDefs();
      const filterModel = gridApi.getFilterModel();
      // console.log(`zzz filterModel`, JSON.stringify(filterModel));
      filterModel['margin'] = null;

      _.map(columnDefs, (columnDef) => {
        if (_.includes(['enLeading', 'enTrailing'], columnDef.groupId)) {
          _.map(columnDef.children, (childColDef) => (childColDef.hide = false));
        }
      });
      if (filters.marginSide !== FILTERS.MARGIN_SIDE.ANY) {
        const isLeadingSide = filters.marginSide === FILTERS.MARGIN_SIDE.LEADING;
        const multiplier = isLeadingSide ? 1 : -1;
        let type = '';
        let filterValue = 0;
        switch (filters.margin) {
          case FILTERS.MARGIN.BY1:
            filterValue = 1 * multiplier;
            type = 'equals';
            break;
          case FILTERS.MARGIN.BY2:
            filterValue = 2 * multiplier;
            type = 'equals';
            break;
          case FILTERS.MARGIN.BY3:
            filterValue = 3 * multiplier;
            type = isLeadingSide ? 'greaterThanOrEqual' : 'lessThanOrEqual';
            break;
          case FILTERS.MARGIN.ANY:
          default:
            filterValue = 1 * multiplier;
            type = isLeadingSide ? 'greaterThanOrEqual' : 'lessThanOrEqual';
            break;
        }

        // if (filterValue === 0) {
        if (isLeadingSide) {
          // type = 'greaterThan';
          _.map(columnDefs, (columnDef) => {
            if (columnDef.groupId === 'enTrailing') {
              _.map(columnDef.children, (childColDef) => (childColDef.hide = true));
            }
          });
        } else {
          // type = 'lessThan';
          _.map(columnDefs, (columnDef) => {
            if (columnDef.groupId === 'enLeading') {
              _.map(columnDef.children, (childColDef) => (childColDef.hide = true));
            }
          });
        }
        // }
        filterModel['margin'] = {
          filterType: 'number',
          type: type,
          filter: filterValue,
        };
      }

      let teamFilterValue = null;
      if (filters.team !== FILTERS.TEAM.ALL) {
        teamFilterValue = filters.team;
      }
      filterModel['team'] = {
        filterType: 'text',
        type: 'equals',
        filter: teamFilterValue,
      };

      await gridApi.setFilterModel(filterModel);
      gridApi.onFilterChanged();
      await gridApi.setGridOption('columnDefs', columnDefs);
      // await gridApi.setColumnDefs(columnDefs);
      // gridApi.onFilterChanged();

      setTimeout(() => {
        gridApi.autoSizeAllColumns();
      }, 200);

      // console.log(`zzz `, columnDefs);
      // console.log(`zzz COLUMN_DEFS`, COLUMN_DEFS);
    })();
  }, [filters, grid]);

  const rowData = allRows;
  // const rowData = _.filter(allRows, (entry) => {
  //   return leadingTrailing === FILTERS.MARGIN_SIDE.ANY || entry?.en?.[leadingTrailing]?.margin > 0;
  // });
  // console.log(`zzz rowData`, rowData, filters);

  // https://www.ag-grid.com/react-data-grid/column-properties/
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,

      // suppressSizeToFit: true,
      // flex: 1,
      // floatingFilter: true,
      // cellDataType: false,
      cellClass: styles.gridBodyCell,
      headerClass: styles.gridHeaderCell,
      // groupsHeaderClass: styles.gridHeaderCell,
      // wrapText: true,
      // autoHeight: true,
      comparator: GridUtils.valueComparer,
    }),
    []
  );

  const onGridReady = () => {};

  const onFirstDataRendered = useCallback(() => {
    // setTimeout(() => {
    //   grid?.columnApi.autoSizeAllColumns();
    // }, 200);
  }, []);

  const onFilterMarginSideChange = (event, newValue) => {
    if (!newValue) return;
    setFilters(_.merge({}, filters, { [FILTERS.MARGIN_SIDE.KEY]: newValue }));
  };
  const onFilterMarginChange = (event, newValue) => {
    if (!newValue) return;
    setFilters(_.merge({}, filters, { [FILTERS.MARGIN.KEY]: newValue }));
  };
  const onFilterTeamChange = (newValue) => {
    if (selectedTeamCode) return;
    setFilters(_.merge({}, filters, { [FILTERS.TEAM.KEY]: newValue }));
  };

  const onCellClicked = async (event) => {
    // console.log(`zzz onCellClicked `, event);
    const gridApi = grid?.api;
    if (!gridApi) return;
    if (!_.includes(['team', 'oppTeam'], event?.colDef?.colId)) return;

    onFilterTeamChange(event.value?.teamCode);
  };

  const renderTeamOptions = () => {
    if (selectedTeamCode) return null;
    const teamOptions = _.map(Teams.TEAMS_BY_CODE, (team) => {
      const logo = <img className={styles.teamLogo} src={`/assets/nhl/${team.code}.svg`} alt='logo' />;
      return (
        <MenuItem key={`teamOption[${team.code}]`} className={styles.teamOption} value={team.code}>
          <div className={styles.teamOptionContent}>
            {logo}
            <div className={styles.teamCode}>{team.code}</div>
          </div>
        </MenuItem>
      );
    });

    return (
      <Select
        variant='outlined'
        id='team-select'
        classes={{ select: styles.teamSelect }}
        value={filters.team}
        onChange={(event) => {
          onFilterTeamChange(event.target.value);
        }}>
        <MenuItem className={styles.teamOption} value={FILTERS.TEAM.ALL}>
          All Teams
        </MenuItem>
        {teamOptions}
      </Select>
    );
  };

  return (
    <Paper className={cx(styles.container, props.className)}>
      <div className={styles.filtersContainer}>
        {renderTeamOptions()}
        <ToggleButtonGroup classes={{ grouped: styles.filterButtons }} size='small' color='primary' value={filters.marginSide} exclusive onChange={onFilterMarginSideChange}>
          <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN_SIDE.ANY}>
            Any
          </ToggleButton>
          <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN_SIDE.LEADING}>
            Leading
          </ToggleButton>
          <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN_SIDE.TRAILING}>
            Trailing
          </ToggleButton>
        </ToggleButtonGroup>
        {filters.marginSide !== FILTERS.MARGIN_SIDE.ANY && (
          <ToggleButtonGroup classes={{ grouped: styles.filterButtons }} size='small' color='primary' value={filters.margin} exclusive onChange={onFilterMarginChange}>
            <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN.ANY}>
              Any
            </ToggleButton>
            <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN.BY1}>
              by1
            </ToggleButton>
            <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN.BY2}>
              by2
            </ToggleButton>
            <ToggleButton classes={{ selected: styles.filterButtonSelected }} value={FILTERS.MARGIN.BY3}>
              by3+
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </div>
      <div className={cx('ag-theme-balham', styles.gridContainer)}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={false}
          rowSelection='multiple'
          suppressLoadingOverlay={true}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onCellClicked={onCellClicked}
          autoSizeStrategy={{ type: 'fitCellContents' }}
          // debug
        />
      </div>
    </Paper>
  );
};


NhlStatsEmptyNetGameLog.FILTERS = FILTERS;
NhlStatsEmptyNetGameLog.propTypes = {
  className: PropTypes.string,
  selectedTeamCode: PropTypes.string,
  marginSide: PropTypes.string,
};

export default NhlStatsEmptyNetGameLog;
