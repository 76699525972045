import _ from 'lodash';

const DT_FORMATTER = new Intl.DateTimeFormat('en-US', { timeZone: 'America/Los_Angeles' });

class Utils {
  static dateToDayString = (date, sep = '-') => {
    if (!date) return '';
    const dateParts = {};
    _.forEach(DT_FORMATTER.formatToParts(date), (datePart) => {
      dateParts[datePart.type] = datePart.value;
    });
    return `${dateParts.year}${sep}${String(dateParts.month).padStart(2, 0)}${sep}${String(dateParts.day).padStart(2, 0)}`;
  };

  static formatTime = (seconds) => {
    const m = Math.trunc(seconds / 60);
    const s = Math.trunc(seconds % 60);
    return `${m}:${_.padStart(s, 2, '0')}`;
  };

  static formatGameStartTime = (dateTime) => {
    const startDate = new Date(dateTime);
    return Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(startDate);
  };

  static fetchProxy = async (targetUrl, fetchOptions) => {
    // const url = new URL(targetUrl);
    const url = new URL(`https://55t3nqshdrjzn4pq2x2zrz74me0nljzi.lambda-url.us-east-1.on.aws/`);
    url.searchParams.set('targetUrl', targetUrl);
    // if (json) {
    //   url.searchParams.set('json', true);
    // }
    return fetch(url, fetchOptions);
  };

  static isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };
}

export default Utils;
