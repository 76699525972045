import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import styles from './Page.module.scss';

const Page = (props) => {
  const { className, title, headerContent, children } = props;
  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>{title}</h1>
        {headerContent}
      </div>
    );
  };

  const renderBody = () => {
    if (_.isEmpty(children)) return null;
    return <div className={cx(styles.body)}>{children}</div>;
  };

  return (
    <div className={cx(styles.container, className)}>
      {renderHeader()}
      {renderBody()}
    </div>
  );
};

Page.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Page;
