import _ from 'lodash';
import cx from 'classnames';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import styles from './GridUtils.module.scss';

class GridUtils {
  static formatTime = (seconds) => {
    const m = Math.trunc(seconds / 60);
    const s = Math.trunc(seconds % 60);
    return `${m}:${_.padStart(s, 2, '0')}`;
  };

  static valueComparer = (valueA, valueB, nodeA, nodeB, isDescending) => {
    if (_.isNil(valueA)) return isDescending ? -1 : 1;
    if (_.isNil(valueB)) return isDescending ? 1 : -1;
    if (valueA === valueB) return 0;
    return valueA > valueB ? 1 : -1;
  };

  static getValueObjectComparer = (field) => {
    return (valueA, valueB, ...args) => {
      return GridUtils.valueComparer(valueA?.[field], valueB?.[field], ...args);
    };
  };

  static median = (values) => {
    let _values = _.compact(values);
    if (_.isEmpty(_values)) return 0;
    _values.sort(function (a, b) {
      return a - b;
    });

    let half = Math.floor(_values.length / 2);

    if (_values.length % 2) return _values[half];

    return Math.floor((_values[half - 1] + _values[half]) / 2);
  };

  static calcPercentage = (value, max, precision = 2) => {
    if (!value) return 0;
    if (!max) return 0;
    return _.floor(value / max, precision);
  };

  static resultCellRenderer = (props) => {
    const { data } = props;
    if (_.isEmpty(data)) return null;
    const { matchup, results, stats } = data;
    const runsFor = stats.for.game.runs;
    const runsAgainst = stats.against.game.runs;
    const isWinner = Boolean(results.game.win);
    const winLoss = <div className={cx(styles.winLossTag, { [styles.isWinner]: isWinner })}>{isWinner ? 'W' : 'L'}</div>;
    let suffix = '';
    const icon = <OpenInNewIcon className={styles.icon} color='primary' fontSize='inherit'/>;
    const hasExtraInnings = stats.for.inningsPlayed > 9;
    if (hasExtraInnings) suffix = <div className={styles.suffix}>{`/${stats.for.inningsPlayed}`}</div>;
    return (
      <div
        className={cx(styles.resultContent)}
        onClick={() => {
          const url = new URL(`https://www.mlb.com/`);
          url.pathname = `gameday/${matchup.id}`;
          window.open(url.toString(), `MLB[${matchup.id}]`);
        }}>
        {winLoss}
        <div className={styles.score}>
          <div className={styles.runs}>{runsFor}</div>-<div className={styles.runs}>{runsAgainst}</div> {suffix}
        </div>
        {icon}
      </div>
    );
  };

  static getTeamCellValueGetter = (teamField, homeValue) => {
    return ({ data }) => {
      const teamCode = _.get(data, teamField);
      const isHome = data?.matchup.homeAway === homeValue;
      return { teamCode, isHome };
    };
  };

  static teamCellRenderer = ({ value }) => {
    if (_.isEmpty(value)) return null;
    const { teamCode, isHome } = value;
    const atSymbol = isHome ? '@' : '';
    const logo = <img className={styles.teamLogo} src={`/assets/mlb/logos/${teamCode}.svg`} alt='logo' />;
    const teamName = <div className={styles.teamName}>{`${atSymbol}${teamCode}`}</div>;
    return (
      <div className={cx(styles.gridBodyCell, styles.teamNameCell)}>
        {teamName}
        {logo}
      </div>
    );
  };

  static columnDefs = {
    date: {
      colId: 'date',
      field: 'matchup.date',
      headerName: 'Date',
      cellDataType: 'date',
      sortable: true,
      sort: 'desc',
      valueGetter: ({ data }) => {
        return new Date(data?.matchup.date);
      },
    },
    // {
    //   field: 'homeAway',
    //   headerName: 'Home?',
    // },
    team: {
      colId: 'team',
      headerName: 'Team',
      valueGetter: GridUtils.getTeamCellValueGetter('matchup.team', 'home'),
      filterValueGetter: 'data.matchup.team',
      cellRenderer: GridUtils.teamCellRenderer,
      cellClass: styles.primaryTeamCell,
      cellDataType: 'object',
    },
    oppTeam: {
      colId: 'oppTeam',
      headerName: 'Opp',
      valueGetter: GridUtils.getTeamCellValueGetter('matchup.oppTeam', 'away'),
      filterValueGetter: 'data.matchup.oppTeam',
      cellRenderer: GridUtils.teamCellRenderer,
      cellDataType: 'object',
    },
    result: {
      colId: 'result',
      headerName: 'Result',
      field: 'results',
      cellClass: styles.resultCell,
      cellRenderer: GridUtils.resultCellRenderer,
      cellDataType: 'object',
    },
    pppForGroup: {
      groupId: 'pppFor',
      headerName: 'PPP For',
      headerClass: styles.gridHeaderCell,
      marryChildren: true,
      children: [
        {
          colId: 'pppFor-margin',
          headerName: 'Margin',
          sortable: true,
          // sortingOrder: ['desc', 'asc', null],
          valueGetter: ({ data }) => {
            const { sits } = data;
            if (sits.for.ppp.trailingChance || sits.for.ppp.leadingChance) {
              return sits.for.ppp.runDiff;
            }
            return ``;
          },
          cellDataType: 'text',
        },
        {
          colId: 'pppFor-inning',
          headerName: 'Inning',
          // sortingOrder: ['desc', 'asc', null],
          valueGetter: ({ data }) => {
            const { sits } = data;
            if (sits.for.ppp.trailingPpp || sits.for.ppp.leadingPpp) {
              const half = String(sits.for.ppp.halfInning).substring(0, 3).toUpperCase();
              return `${half} ${sits.for.ppp.inning}`;
            }
            return ``;
          },
          cellDataType: 'text',
        },
        {
          colId: 'pppFor-player',
          headerName: '"Pitcher"',
          // sortingOrder: ['desc', 'asc', null],
          valueGetter: ({ data }) => {
            const { sits } = data;
            const { player } = sits.for.ppp;
            if (!player.id) return `—`;
            return `${String(player.first).charAt(0)}. ${player.last} (${player.pos})`;
          },
          cellDataType: 'text',
        },
        {
          colId: 'pppFor-outcome',
          headerName: 'Outcome',
          valueGetter: ({ data }) => {
            const { sits } = data;
            const pStats = sits.for.ppp.player?.stats?.pitching;
            if (_.isEmpty(pStats)) return ``;
            const { outs, hits, runs } = pStats;
            const ip = `${Math.floor(outs / 3)}.${outs % 3}`;
            return `${ip} IP, ${hits} H, ${runs} R`;
          },
          cellDataType: 'text',
        },
      ],
    },
    pppAgainstGroup: {
      groupId: 'pppAgainst',
      headerName: 'PPP Against',
      headerClass: styles.gridHeaderCell,
      marryChildren: true,
      children: [
        {
          colId: 'pppAgainst-margin',
          headerName: 'Margin',
          sortable: true,
          sortingOrder: ['desc', 'asc', null],
          valueGetter: ({ data }) => {
            const { sits } = data;
            if (sits.against.ppp.trailingChance || sits.against.ppp.leadingChance) {
              return sits.against.ppp.runDiff;
            }
            return ``;
          },
          cellDataType: 'text',
        },
        {
          colId: 'pppAgainst-inning',
          headerName: 'Inning',
          // sortingOrder: ['desc', 'asc', null],
          valueGetter: ({ data }) => {
            const { sits } = data;
            if (sits.against.ppp.trailingPpp || sits.against.ppp.leadingPpp) {
              const half = String(sits.against.ppp.halfInning).substring(0, 3).toUpperCase();
              return `${half} ${sits.against.ppp.inning}`;
            }
            return ``;
          },
          cellDataType: 'text',
        },
        {
          colId: 'pppAgainst-player',
          headerName: '"Pitcher"',
          // sortingOrder: ['desc', 'asc', null],
          valueGetter: ({ data }) => {
            const { sits } = data;
            const { player } = sits.against.ppp;
            if (!player.id) return `—`;
            return `${String(player.first).charAt(0)}. ${player.last} (${player.pos})`;
          },
          cellDataType: 'text',
        },
        {
          colId: 'pppAgainst-outcome',
          headerName: 'Outcome',
          valueGetter: ({ data }) => {
            const { sits } = data;
            const pStats = sits.against.ppp.player?.stats?.pitching;
            if (_.isEmpty(pStats)) return ``;
            const { outs, hits, runs } = pStats;
            const ip = `${Math.floor(outs / 3)}.${outs % 3}`;
            return `${ip} IP, ${hits} H, ${runs} R`;
          },
          cellDataType: 'text',
        },
      ],
    },
    linescoreInnings: {
      colId: 'linescoreInnings',
      headerName: 'Linescore',
      cellClass: styles.linescoreCell,
      // autoHeight: true,
      // height: 60,
      // sortingOrder: ['desc', 'asc', null],
      cellRenderer: ({ data }) => {
        const { matchup, stats, results } = data;
        const { team, oppTeam } = matchup;

        const numInnings = stats.for.inningsPlayed;
        const hasExtraInnings = stats.for.inningsPlayed > 9;
        const isHome = matchup.homeAway === 'home';
        const awayTeamCode = isHome ? oppTeam : team;
        const homeTeamCode = isHome ? team : oppTeam;
        const homeStats = isHome ? stats.for : stats.against;
        const awayStats = isHome ? stats.against : stats.for;
        const isHomeTeamWinner = homeStats.game.runs > awayStats.game.runs;

        const innings = [];
        for (const homeAway of ['away', 'home']) {
          const isSideHome = homeAway === 'home';
          let teamCode = homeTeamCode;
          let className = styles.inningHome;
          let stats = homeStats;
          if (!isSideHome) {
            teamCode = awayTeamCode;
            className = styles.inningAway;
            stats = awayStats;
          }

          innings.push(
            <div key={`team[${homeAway}]`} className={cx(styles.inningHalf, className, styles.team)}>
              {teamCode}
            </div>
          );

          for (let i = 1; i <= numInnings; i++) {
            const key = `i${i}`;
            let runs = stats[key]?.runs;
            if (i === 9 && !runs && isSideHome && !hasExtraInnings && isHomeTeamWinner) {
              runs = '-';
            }
            const classes = [styles.inningHalf, className];
            if (results[key]?.win) {
              classes.push(styles.win);
            } else if (results[key]?.lose) {
              classes.push(styles.lose);
            }
            innings.push(
              <div key={`${key}[${homeAway}]`} className={cx(classes)}>
                {runs}
              </div>
            );
          }
        }

        return (
          <div className={cx(styles.innings, { [styles.inningsExtra]: hasExtraInnings })} style={{ '--num-innings': numInnings }}>
            {innings}
          </div>
        );
      },
      cellDataType: 'object',
    },
    startingPitchers: {
      colId: 'startingPitchers',
      headerName: 'Starting Pitchers',
      // autoHeight: true,
      cellRenderer: ({ data }) => {
        const { matchup } = data;
        const { starters, team, oppTeam } = matchup;
        const isHome = matchup.homeAway === 'home';
        const awayTeamCode = isHome ? oppTeam : team;
        const homeTeamCode = isHome ? team : oppTeam;

        const renderPitcherName = (player) => {
          if (!player.id) return `—`;
          return `${String(player.first).charAt(0)}. ${player.last} (${player.hand})`;
        };

        return (
          <div className={styles.startingPitchers}>
            <div className={cx(styles.cell, styles.team)}>{awayTeamCode}</div>
            <div className={cx(styles.cell)}>{renderPitcherName(starters.away)}</div>
            <div className={cx(styles.cell, styles.team)}>{homeTeamCode}</div>
            <div className={cx(styles.cell)}>{renderPitcherName(starters.home)}</div>
          </div>
        );
      },
      // cellDataType: 'object',
    },
  };
}

export default GridUtils;
