import _ from 'lodash';

const TEAMS = [
  { id: 1, code: 'NJD', location: 'New Jersey', name: 'Devils' },
  { id: 2, code: 'NYI', location: 'New York', name: 'Islanders' },
  { id: 3, code: 'NYR', location: 'New York', name: 'Rangers' },
  { id: 4, code: 'PHI', location: 'Philadelphia', name: 'Flyers' },
  { id: 5, code: 'PIT', location: 'Pittsburgh', name: 'Penguins' },
  { id: 6, code: 'BOS', location: 'Boston', name: 'Bruins' },
  { id: 7, code: 'BUF', location: 'Buffalo', name: 'Sabres' },
  { id: 8, code: 'MTL', location: 'Montreal', name: 'Canadiens' },
  { id: 9, code: 'OTT', location: 'Ottawa', name: 'Senators' },
  { id: 10, code: 'TOR', location: 'Toronto', name: 'Maple Leafs' },
  { id: 12, code: 'CAR', location: 'Carolina', name: 'Hurricane' },
  { id: 13, code: 'FLA', location: 'Florida', name: 'Panthers' },
  { id: 14, code: 'TBL', location: 'Tampa Bay', name: 'Lightning' },
  { id: 15, code: 'WSH', location: 'Washington', name: 'Capitals' },
  { id: 16, code: 'CHI', location: 'Chicago', name: 'Blackhawks' },
  { id: 17, code: 'DET', location: 'Detroit', name: 'Red Wings' },
  { id: 18, code: 'NSH', location: 'Nashville', name: 'Predators' },
  { id: 19, code: 'STL', location: 'St Louis', name: 'Blues' },
  { id: 20, code: 'CGY', location: 'Calgary', name: 'Flames' },
  { id: 21, code: 'COL', location: 'Colorado', name: 'Avalanche' },
  { id: 22, code: 'EDM', location: 'Edmonton', name: 'Oilers' },
  { id: 23, code: 'VAN', location: 'Vancouver', name: 'Canucks' },
  { id: 24, code: 'ANA', location: 'Anaheim', name: 'Ducks' },
  { id: 25, code: 'DAL', location: 'Dallas', name: 'Stars' },
  { id: 26, code: 'LAK', location: 'Los Angeles', name: 'Kings' },
  { id: 28, code: 'SJS', location: 'San Jose', name: 'Sharks' },
  { id: 29, code: 'CBJ', location: 'Columbus', name: 'Blue Jackets' },
  { id: 30, code: 'MIN', location: 'Minnesota', name: 'Wild' },
  { id: 52, code: 'WPG', location: 'Winnipeg', name: 'Jets' },
  { id: 53, code: 'ARI', location: 'Arizona', name: 'Coyotes' },
  { id: 54, code: 'VGK', location: 'Vegas', name: 'Golden Knights' },
  { id: 55, code: 'SEA', location: 'Seattle', name: 'Kraken' },
  { id: 59, code: 'UTA', location: 'Utah', name: 'Hockey Club' },
];

class Teams {
  static TEAMS_BY_ID = {};
  static TEAMS_BY_LOCATION = {};
  static TEAMS_BY_CODE = {};
}

const sortedTeams = _.orderBy(TEAMS, 'code');

_.forEach(sortedTeams, (team) => {
  Teams.TEAMS_BY_ID[team.id] = team;
  Teams.TEAMS_BY_LOCATION[team.location] = team;
  Teams.TEAMS_BY_CODE[team.code] = team;
});

export default Teams;
