import React from 'react';
import { useParams } from 'react-router-dom';

import MlbPpp from './ppp/MlbPpp';
import MlbPppPre2023 from './ppp-pre2023/MlbPpp';

const MlbPppRoutes = () => {
  const { season } = useParams();

  try {
    const _season = parseInt(season);
    if (_season >= 2023) {
      if (_season < 2023) {
        return <MlbPppPre2023 season={_season} />;
      } else {
        return <MlbPpp season={_season} />;
      }
    }
  } catch (e) {
    console.error(`Unknown season path param`, e);
  }

  return <MlbPpp />;
};

export default MlbPppRoutes;
