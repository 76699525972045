import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Utils from 'Utils';
import NhlMatchupsContext from './NhlMatchupsContext';
import NhlMatchupsService from './NhlMatchupsService';
import NhlMatchup from './NhlMatchup';

const NhlMatchupsProvider = ({ season = 2024, children = [] }) => {
  const [date, setDate] = useState(new Date());
  const [fullSchedule, setFullSchedule] = useState(null);
  const [matchups, setMatchups] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const day = Utils.dateToDayString(date);

  useEffect(() => {
    setIsFetching(true);
    setFetchError(null);
    const loader = async () => {
      try {
        const schedule = await NhlMatchupsService.fetchFullSchedule(season);
        setFullSchedule(schedule);
      } catch (e) {
        setFullSchedule(null);
        setMatchups(null);
        setFetchError(`Unable to load data`);
        console.error(`Unable to load data`, e);
      }

      setIsFetching(false);
    };
    loader();
    return () => {
      setFullSchedule(null);
      setMatchups(null);
      setIsFetching(false);
    };
  }, [season]);

  useEffect(() => {
    const _matchups = matchups;
    return () => {
      _.map(_matchups, (matchup) => {
        // console.log(`stopPolling for`, matchup);
        matchup.stopPolling();
      });
    };
  }, [matchups]);

  useEffect(() => {
    const schedule = _.filter(_.values(fullSchedule), { day: day });
    const matchups = {};
    _.map(schedule, (matchupInfo) => {
      const { id } = matchupInfo;
      matchups[id] = new NhlMatchup(matchupInfo);
    });
    setMatchups(matchups);
  }, [day, fullSchedule]);

  const contextValue = {
    fetchError,
    isFetching,
    day,
    fullSchedule,
    matchups,
    date,
    setDate,
  };

  return <NhlMatchupsContext.Provider value={contextValue}>{children}</NhlMatchupsContext.Provider>;
};

// NhlMatchupsProvider.defaultProps = {
//   season: 2024,
//   date: new Date(),
// };
NhlMatchupsProvider.propTypes = {
  season: PropTypes.number,
  date: PropTypes.object,
};

export default NhlMatchupsProvider;
