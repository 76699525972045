import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormControl, MenuItem, Paper, Select } from '@mui/material';

import Page from 'common/Page';
import MlbStatsProvider from './MlbStatsProvider';
import MlbStats from './MlbStats';

import styles from './MlbStatsPage.module.scss';

const MlbStatsPage = (props) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSeason, setSelectedSeason] = useState(2024);

  // useEffect(() => {
  //   if (DEFAULT_SEASON !== selectedSeason) {
  //     setSearchParams({ ...searchParams, season: selectedSeason });
  //   } else {
  //     searchParams.delete('season');
  //     setSearchParams(searchParams);
  //   }
  // }, [subView]);

  const seasonSelector = (
    <Paper>
      <FormControl size='small'>
        <Select
          variant='outlined'
          id='season-select'
          classes={{ select: styles.seasonSelect }}
          value={selectedSeason}
          onChange={(event) => {
            setSelectedSeason(event.target.value);
          }}>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
        </Select>
      </FormControl>
    </Paper>
  );

  const headerContent = <div className={styles.headerActions}>{seasonSelector}</div>;
  return (
    <Page className={cx(styles.container, props.className)} title={'MLB Stats'} headerContent={headerContent}>
      <MlbStatsProvider season={selectedSeason}>
        <MlbStats />
      </MlbStatsProvider>
    </Page>
  );
};

MlbStatsPage.propTypes = {
  className: PropTypes.string,
};

export default MlbStatsPage;
