import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { Tooltip } from '@mui/material';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import NhlStatsContext from 'nhl/stats/NhlStatsContext';
import GridUtils from 'nhl/stats/GridUtils';

import styles from './NhlStatsEmptyNetTeamSummary.module.scss';

const resultCellRenderer = ({ value }) => {
  if (_.isEmpty(value)) return null;
  const { goalsFor, goalsAllowed, overtime, shootout } = value;
  const isWinner = goalsFor > goalsAllowed;
  const winLoss = <div className={cx(styles.winLossTag, { [styles.isWinner]: isWinner })}>{isWinner ? 'W' : 'L'}</div>;
  let suffix = '';
  if (overtime) suffix = 'OT';
  if (shootout) suffix = 'SO';
  return (
    <div className={cx(styles.gridBodyCell)}>
      {winLoss}
      <div className={styles.score}>
        {goalsFor}&ndash;{goalsAllowed} {suffix}
      </div>
    </div>
  );
};

const getTeamCellValueGetter = (teamField, homeValue) => {
  return ({ data }) => {
    const teamCode = data?.[teamField];
    const isHome = data?.homeAway === homeValue;
    return { teamCode, isHome };
  };
};

const teamCellRenderer = ({ value }) => {
  if (_.isEmpty(value)) return null;
  const { teamCode, isHome } = value;
  const atSymbol = isHome ? '@' : '';
  const logo = <img className={styles.teamLogo} src={`/assets/nhl/${teamCode}.svg`} alt='logo' />;
  const teamName = <div className={styles.teamName}>{`${atSymbol}${teamCode}`}</div>;
  return (
    <div className={cx(styles.gridBodyCell, styles.teamNameCell)}>
      {logo}
      {teamName}
    </div>
  );
};

const createValueGetter = (baseField, numeratorField, denominatorField = 'count') => {
  return (params) => {
    const object = _.get(params.data, baseField);
    return { value: object?.[numeratorField], maxValue: object?.[denominatorField] };
  };
};

const valueWithPercentCellRenderer = (params) => {
  if (_.isEmpty(params.value)) return null;

  const { value, maxValue } = params.value;
  let percentage = '\u2013';
  if (maxValue) {
    percentage = (
      <>
        {`${_.floor((value / maxValue) * 100)}`}
        <div className={styles.percentageChar}>%</div>
      </>
    );
  }
  return (
    <div className={styles.valueWithPercent}>
      <div className={styles.value}>{value}</div>
      <div className={styles.percentage}>{percentage}</div>
    </div>
  );
};

const getEnInfoCellValueGetter = (enField, leadingTrailingField) => {
  return ({ data }) => {
    const enData = data?.[enField]?.[leadingTrailingField];
    if (_.isNil(enData?.start)) return null;
    return enData;
  };
};

const enInfoCellRenderer = ({ value }) => {
  if (!value?.start) return null;
  const { start, duration } = value;
  return (
    <div className={cx(styles.gridBodyCell)}>
      {GridUtils.formatTime(start)} rem (for {GridUtils.formatTime(duration)})
    </div>
  );
};

const getEnOutcomeCellValueGetter = (enField, leadingTrailingField, goalsForField, goalsAllowedField) => {
  return ({ data }) => {
    const enData = data?.[enField]?.[leadingTrailingField];
    // const goalsFor = enData?.[goalsForField];
    // const goalsAllowed = enData?.[goalsAllowedField];

    if (_.isNil(enData?.start)) return null;
    const { enGoals, goals } = enData;

    return { enGoals, goals };
  };
};

const enOutcomeCellRenderer = ({ value }) => {
  if (_.isNil(value)) return null;
  const { enGoals, goals } = value;

  let classes = [styles.outcomeLabel];
  let label = 'NG';
  let tooltipText = 'No Goals during situation';

  if (enGoals >= 1) {
    classes.push(styles.outcomeLabelENG);
    label = 'ENG';
    tooltipText = `Empty Net Goal Scored`;
  } else if (goals >= 1) {
    classes.push(styles.outcomeLabelCBG);
    label = 'CBG';
    tooltipText = `Comeback Goal Scored`;
  }

  if (goals + enGoals >= 2) {
    label += `+`;
    tooltipText = (
      <>
        {`${enGoals}x Empty Net Goals`}
        <br />
        {`${goals}x Comeback Goals`}
      </>
    );
  }

  return (
    <Tooltip title={tooltipText} placement='top' arrow>
      <div className={cx(classes)}>{label}</div>
    </Tooltip>
  );
};

const enMarginCellValueGetter = ({ data }) => {
  let margin = data.en.leading.margin;
  if (_.isNil(margin)) {
    margin = data.en.trailing.margin;
    if (margin > 0) margin = margin * -1;
  }
  if (_.isNil(margin)) return null;
  return margin;
};

const enOutcomeComparer = (valueA, valueB, ...args) => {
  let _valueA = null;
  let _valueB = null;

  if (!_.isEmpty(valueA)) {
    _valueA = valueA?.enGoals * 10 + valueA?.goals;
  }

  if (!_.isEmpty(valueB)) {
    _valueB = valueB?.enGoals * 10 + valueB?.goals;
  }

  return GridUtils.valueComparer(_valueA, _valueB, ...args);
};

const DEFAULT_TEAM_EN_SUMMARY_SPLIT = {
  count: 0,
  goalsFor: 0,
  goalsAllowed: 0,
  noGoal: 0,
  goalsForPerc: 0,
  goalsAllowedPerc: 0,
  noGoalPerc: 0,
  totalDuration: 0,
  startTimes: [],
  medianStartTime: 0,
};

const NhlStatsEmptyNetTeamSummary = (props) => {
  const gridRef = useRef();
  const { data } = useContext(NhlStatsContext);
  const { teams, leagueEmptyNet } = data;
  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    const teamSummaries = _.map(teams, (teamData) => {
      const { code, summary } = teamData;
      return {
        teamCode: code,
        ...summary,
      };
    });
    // console.log(`teamEmptyNet`, teams, teamSummaries);
    const rows = _.orderBy(teamSummaries, ['teamCode'], ['asc']);
    setRowData(rows);
    return () => {
      setRowData(null);
    };
  }, [teams]);

  const [columnDefs] = useState([
    {
      headerName: 'Team',
      valueGetter: getTeamCellValueGetter('teamCode', 'home'),
      filterValueGetter: 'data.teamCode',
      cellRenderer: teamCellRenderer,
      // cellClass: styles.primaryTeamCell,
      comparator: GridUtils.getValueObjectComparer('teamCode'),
      cellDataType: 'object',
      filter: true,
      width: 100,
      pinned: true,
    },
    {
      headerName: 'GP',
      field: 'gamesPlayed',
      cellDataType: 'number',
    },
    {
      groupId: 'any',
      headerName: 'Leading (any first pulls)',
      headerClass: styles.gridHeaderCell,
      marryChildren: true,
      // flex: 1,
      children: [
        {
          headerName: '#',
          field: 'leading.any.count',
        },
        {
          headerName: 'ENG',
          // field: 'leading.any.goalsFor',
          cellClass: cx(styles.goalsForCell, styles.gridBodyCell),
          cellRenderer: valueWithPercentCellRenderer,
          valueGetter: createValueGetter('leading.any', 'goalsFor'),
        },
        {
          headerName: 'CBG',
          field: 'leading.any.goalsAllowed',
        },
        {
          headerName: 'NG',
          field: 'leading.any.noGoal',
        },
      ],
    },
    {
      groupId: 'leading-by-1',
      headerName: 'Leading by 1',
      headerClass: styles.gridHeaderCell,
      marryChildren: true,
      // flex: 1,
      children: [
        {
          headerName: '#',
          field: 'leading.by1.count',
        },
        {
          headerName: 'ENG',
          field: 'leading.by1.goalsFor',
        },
        {
          headerName: 'CBG',
          field: 'leading.by1.goalsAllowed',
        },
        {
          headerName: 'NG',
          field: 'leading.by1.noGoal',
        },
      ],
    },
    {
      groupId: 'leading-by-2',
      headerName: 'Leading by 2',
      headerClass: styles.gridHeaderCell,
      marryChildren: true,
      // flex: 1,
      children: [
        {
          headerName: '#',
          field: 'leading.by2.count',
        },
        {
          headerName: 'ENG',
          field: 'leading.by2.goalsFor',
        },
        {
          headerName: 'CBG',
          field: 'leading.by2.goalsAllowed',
        },
        {
          headerName: 'NG',
          field: 'leading.by2.noGoal',
        },
      ],
    },
    {
      groupId: 'leading-by-3',
      headerName: 'Leading by 3+',
      headerClass: styles.gridHeaderCell,
      marryChildren: true,
      // flex: 1,
      children: [
        {
          headerName: '#',
          field: 'leading.by3p.count',
        },
        {
          headerName: 'ENG',
          field: 'leading.by3p.goalsFor',
        },
        {
          headerName: 'CBG',
          field: 'leading.by3p.goalsAllowed',
        },
        {
          headerName: 'NG',
          field: 'leading.by3p.noGoal',
        },
      ],
    },
  ]);

  // https://www.ag-grid.com/react-data-grid/column-properties/
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: false,
      // suppressSizeToFit: true,
      flex: 0,
      // floatingFilter: true,
      cellDataType: false,
      cellClass: styles.gridBodyCell,
      headerClass: styles.gridHeaderCell,
      width: 75,
      // groupsHeaderClass: styles.gridHeaderCell,
      // wrapText: true,
      // autoHeight: true,
      comparator: GridUtils.valueComparer,
      sortingOrder: ['desc', 'asc', null],
    }),
    []
  );

  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridRef.current?.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current?.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  const onGridReady = useCallback(() => {
    // console.log(`zzz onGridReady`);
  }, []);
  const onFirstDataRendered = useCallback(() => {
    // console.log(`zzz onFirstDataRendered`);
    // autoSizeAll(true);
  }, [rowData, autoSizeAll]);

  return (
    <div className={cx(styles.container, props.className)}>
      {/* <div className={styles.header}>test</div> */}
      <div className={cx('ag-theme-balham', styles.gridContainer)}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          // animateRows={true}
          rowSelection='multiple'
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          // onCellClicked={autoSizeAll}
        />
      </div>
    </div>
  );
};

NhlStatsEmptyNetTeamSummary.propTypes = {
  className: PropTypes.string,
};

export default NhlStatsEmptyNetTeamSummary;
