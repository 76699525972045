import React from 'react';
import PropTypes from 'prop-types';
import { StyledEngineProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './Home';
// import EmptyNet from './ahl/stats/EmptyNet';
import NhlGameTracker from 'nhl/gametracker/NhlGameTracker';
import MlbStatsPage from 'mlb/stats/MlbStatsPage';
import MlbPppRoutes from 'mlb/stats/MlbPppRoutes';
// import Matchups from 'mlb/matchups/Matchups';
// import NhlStatsPage from 'nhl/stats/NhlStatsPage';
import NhlStatsEmptyNetPage from 'nhl/stats/empty-net/NhlStatsEmptyNetPage';
// import NhlStatsPeriodsPage from 'nhl/stats/NhlStatsPeriodsPage';
import HockeyGameTracker from 'hockey/gametracker/HockeyGameTracker';
// import NhlMatchupsPage from 'nhl/matchups/NhlMatchupsPage';

import styles from './App.module.scss';

export default class App extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <main className={styles.app}>
          <BrowserRouter basename='/'>
            <Routes>
              <Route index element={<Home />}></Route>
              <Route path='hockey'>
                <Route index element={<div>NHL</div>}></Route>
                <Route path='gametracker' element={<HockeyGameTracker />}></Route>
              </Route>
              <Route path='nhl'>
                <Route index element={<div>NHL</div>}></Route>
                <Route path='gametracker' element={<NhlGameTracker />}></Route>
                {/* <Route path='matchups' element={<NhlMatchupsPage />}></Route> */}
                <Route path='stats'>
                  {/* <Route index element={<NhlStatsPage />}></Route> */}
                  <Route path='empty-net' element={<NhlStatsEmptyNetPage />}></Route>
                  {/* <Route path='periods' element={<NhlStatsPeriodsPage />}></Route> */}
                </Route>
              </Route>
              {/* <Route path='ahl'> */}
                {/* <Route index element={<div>AHL</div>}></Route> */}
                {/* <Route path='matchups' element={<div>AHL Matchups</div>}></Route> */}
                {/* <Route path='stats'>
                  <Route index element={<div>AHL Stats</div>}></Route>
                  <Route path='empty-net' element={<EmptyNet />}></Route>
                  <Route path='periods' element={<div>AHL Periods</div>}></Route>
                </Route> */}
              {/* </Route> */}
              <Route path='mlb'>
                <Route index element={<div>MLB</div>}></Route>
                {/* <Route path='matchups' element={<Matchups />}></Route> */}
                <Route path='stats/:subview?'>
                  <Route index element={<MlbStatsPage />}></Route>
                  <Route path='ppp/:season?' element={<MlbPppRoutes />}></Route>
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </main>
      </StyledEngineProvider>
    );
  }
}
