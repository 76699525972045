// const GAME_STATES = {
//   FUT: "FUT",
//   PRE: "PRE",
//   LIVE: "LIVE",
//   CRIT: "CRIT",
//   OVER: "OVER",
//   FINAL: "FINAL",
//   OFF: "OFF",
// }

const GAME_SCHEDULE_STATES = {
  OK: 'OK',
  TBD: 'TBD',
  PPD: 'PPD',
  SUSP: 'SUSP',
  CNCL: 'CNCL',
};

const GAME_OUTCOMES = {
  REG: 'REG',
  OT: 'OT',
  SO: 'SO',
};

const TYPE_GROUP_PREMATCH = {
  FUT: 'FUT',
  PRE: 'PRE',
};

const TYPE_GROUP_LIVE = {
  CRIT: 'LIVE',
  LIVE: 'CRIT',
};

const TYPE_GROUP_ENDED = {
  OVER: 'OVER',
  FINAL: 'FINAL',
  OFF: 'OFF',
};

const GAME_STATE_GROUPS = {
  PREMATCH: TYPE_GROUP_PREMATCH,
  LIVE: TYPE_GROUP_LIVE,
  ENDED: TYPE_GROUP_ENDED,
};

const GAME_STATE_TO_GROUP = {
  [TYPE_GROUP_PREMATCH.FUT]: GAME_STATE_GROUPS.PREMATCH,
  [TYPE_GROUP_PREMATCH.PRE]: GAME_STATE_GROUPS.PREMATCH,

  [TYPE_GROUP_LIVE.CRIT]: GAME_STATE_GROUPS.LIVE,
  [TYPE_GROUP_LIVE.LIVE]: GAME_STATE_GROUPS.LIVE,

  [TYPE_GROUP_ENDED.OVER]: GAME_STATE_GROUPS.ENDED,
  [TYPE_GROUP_ENDED.FINAL]: GAME_STATE_GROUPS.ENDED,
  [TYPE_GROUP_ENDED.OFF]: GAME_STATE_GROUPS.ENDED,
};

const GAME_STATES = {
  ...TYPE_GROUP_PREMATCH,
  ...TYPE_GROUP_LIVE,
  ...TYPE_GROUP_ENDED,
};

const GAME_STATE_SORT_ORDER = {
  [TYPE_GROUP_LIVE.CRIT]: 1,
  [TYPE_GROUP_LIVE.LIVE]: 2,
  [TYPE_GROUP_ENDED.OVER]: 3,
  [TYPE_GROUP_ENDED.FINAL]: 4,
  [TYPE_GROUP_ENDED.OFF]: 5,
  [TYPE_GROUP_PREMATCH.PRE]: 6,
  [TYPE_GROUP_PREMATCH.FUT]: 7,
};

export { GAME_STATES, GAME_SCHEDULE_STATES, GAME_OUTCOMES, GAME_STATE_GROUPS, GAME_STATE_TO_GROUP, GAME_STATE_SORT_ORDER };
