import { createContext } from 'react';

const NhlMatchupsContext = createContext({
  // isFetching: false,
  date: new Date(),
  fullSchedule: null,
  matchups: null,
});

export default NhlMatchupsContext;
