import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Paper } from '@mui/material';

import Teams from 'mlb/Teams';
import Stats from 'mlb/stats/Stats';
import MlbPppTeam from './MlbPppTeam';

import styles from './MlbPpp.module.scss';

const MlbPpp = (props) => {
  const { season } = props;
  const [summaryByTeam, setSummaryByTeam] = useState({});
  const [teamLogs, setTeamLogs] = useState({});
  // const [pppData, setPppData] = useState({});

  useEffect(() => {
    (async () => {
      const data = await Stats.loadSummaryByTeam(season);
      setSummaryByTeam(data.summaryByTeam);
    })();
    (async () => {
      const data = await Stats.loadAllTeamLogs(season);
      setTeamLogs(data.teamLogs);
    })();
  }, [season]);

  // useEffect(() => {
  //   const data = {};
  //   setPppData(data);
  // }, [teamLogs]);

  const renderTeamsHeader = () => {
    return <div className={cx(styles.row, styles.teamsHeader)}>
      <div className={cx(styles.cell, styles.cellLeft)}>Team</div>
      <div className={cx(styles.cell)}>Down 6+</div>
      <div className={cx(styles.cell)}>6</div>
      <div className={cx(styles.cell)}>7</div>
      <div className={cx(styles.cell)}>8</div>
      <div className={cx(styles.cell)}>9+</div>
      <div className={cx(styles.cell)}>Early</div>
      <div className={cx(styles.cell)}>With Lead</div>
      <div className={cx(styles.cell)}></div>
    </div>;
  };

  const renderTeams = () => {
    if (_.isEmpty(summaryByTeam)) return null;
    if (_.isEmpty(teamLogs)) return null;
    return (
      <div className={styles.teamsBody}>
        {_.map(Teams.TEAMS_BY_CODE, (team) => <MlbPppTeam key={team.code} className={styles.row} cellClassName={styles.cell} teamCode={team.code} />)}
      </div>
    );
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          MLB PPP
        </h1>
        <h3 className={styles.subtitle}>
          (when 6+ run margin)
        </h3>
      </div>
      <Paper className={styles.teamsContainer}>
        {renderTeamsHeader()}
        {renderTeams()}
      </Paper>
    </div>
  );
};

MlbPpp.propTypes = {
  className: PropTypes.string,
  season: PropTypes.number.isRequired,
};

export default React.memo(MlbPpp);
