import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Button, Paper } from '@mui/material';

import styles from './HockeyGameTracker.module.scss';

const USE_TEST_DATA = false;
const BUCKET_SUFFIX = USE_TEST_DATA ? `--test` : ``;

const DT_FORMATTER = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'America/Los_Angeles' });
const dateToDayString = (date, sep = '-') => {
  if (!date) return '';
  const dateParts = {};
  _.forEach(DT_FORMATTER.formatToParts(date), (datePart) => {
    dateParts[datePart.type] = datePart.value;
  });
  return `${dateParts.year}${sep}${String(dateParts.month).padStart(2, 0)}${sep}${String(dateParts.day).padStart(2, 0)}`;
};

const LEAGUES = {
  NHL: { key: 'nhl', label: 'NHL', events: {} },
  AHL: { key: 'american-hockey-league', label: 'AHL', events: {} },
  SWEDEN_SHL: { key: 'swedish_elitserien', label: 'Sweden SHL', events: {} },
  FINLAND_LIIGA: { key: 'finnish-sm-liga', label: 'Finland Liiga', events: {} },
  SWISS_NLA: { key: 'swiss-nla', label: 'Swiss NLA', events: {} },
  FINLAND_MESTIS: { key: 'finland-mestis', label: 'Finland Liiga', events: {} },
  SWEDEN_ALLSVENSKAN: { key: 'swedish-allsvenskan', label: 'Sweden Allsvenskan', events: {} },
  GERMAN_DEL: { key: 'german-bundesliga-1', label: 'Germany DEL', events: {} },
  AUSTRIA_ICE: { key: 'austrian-ehl', label: 'Austria ICE', events: {} },
  CZECH_EXTRALIGA: { key: 'czech-extraliga', label: 'Czech Extraliga', events: {} },
  CHL: { key: 'champions-hockey-league', label: 'CHL', events: {} },
}

// Old WH ids
// NHL: { key: 'NHL', label: 'NHL', events: {} },
// AHL: { key: 'AHL', label: 'AHL', events: {} },
// SWEDEN_SHL: { key: 'SWEDEN_SHL', label: 'Sweden SHL', events: {} },
// FINLAND_LIIGA: { key: 'FINLAND_LIIGA', label: 'Finland Liiga', events: {} },
// SWISS_NLA: { key: 'SWISS_NLA', label: 'Swiss NLA', events: {} },
// SWEDEN_ALLSVENSKAN: { key: 'SWEDEN_ALLSVENSKAN', label: 'Sweden Allsvenskan', events: {} },
// GERMAN_DEL: { key: 'GERMAN_DEL', label: 'Germany DEL', events: {} },
// AUSTRIA_ICE: { key: 'AUSTRIA_ICE', label: 'Austria ICE', events: {} },
// CZECH_EXTRALIGA: { key: 'CZECH_EXTRALIGA', label: 'Czech Extraliga', events: {} },
// CHL: { key: 'CHL', label: 'CHL', events: {} },


const HockeyGameTracker = (props) => {
  const [eventsByLeagueName, setEventsByLeagueName] = useState([]);
  const [visibleLeagues, setVisibleLeagues] = useState({});
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      let day = dateToDayString(new Date());
      const customDay = searchParams.get('day');
      console.log(searchParams);
      console.log(customDay);
      if (customDay) {
        day = customDay;
      }
      const response = await fetch(`https://ipl-sportsdata2${BUCKET_SUFFIX}.s3.amazonaws.com/whe/ice-hockey/day/${day}.json`);
      const eventsById = await response.json();
      const events = _.orderBy(_.values(eventsById), 'eventTime');
      const _eventsByLeagueName = _.merge({}, LEAGUES);
      for (const event of events) {
        console.log(event);
        const { leagueName, eventId, scoreboardUrl } = event;
        if (_.isEmpty(scoreboardUrl)) continue;
        const league = _eventsByLeagueName[leagueName];
        if (_.isEmpty(league)) continue;
        league.events[eventId] = event;
      }
      setEventsByLeagueName(_eventsByLeagueName);
    })();
  }, [searchParams]);

  const toggleLeague = (key) => {
    const oldValue = Boolean(visibleLeagues[key]);
    const _visibleLeagues = _.merge({}, visibleLeagues, { [key]: !oldValue });
    setVisibleLeagues(_visibleLeagues);
  }

  console.log(visibleLeagues);

  const renderLeagueEvents = (events) => {
    if (_.isEmpty(events)) return null;
    return (
      <div className={styles.leagueEvents}>
        {_.map(events, (event) => {
          // const { scoreboardUrl, eventTime, awayTeam, homeTeam } = event;
          const { eventId, scoreboardUrl } = event;
          if (!scoreboardUrl) return null;

          return <iframe key={`gametracker[${eventId}]`} className={styles.gameTrackerIframe} src={scoreboardUrl} title={`gametracker[${eventId}]`} />;
        })}
      </div>
    );
  };

  const renderLeagues = (leagues) => {
    if (_.isEmpty(leagues)) return null;
    return (
      <div className={styles.leagues}>
        {_.map(leagues, (league) => {
          const { key, label, events } = league;
          const onClick = () => {
            toggleLeague(key);
          }
          let leagueEvents = null;
          if (visibleLeagues[key]) {
            leagueEvents = renderLeagueEvents(events);
          }
          return (<Paper key={`league[${key}]`} className={styles.league}>
            <Button className={styles.leagueHeader} onClick={onClick}>
              {label} ({_.keys(events).length})
            </Button>
            {leagueEvents}
          </Paper>);
        })}
      </div>
    );
  }

  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Hockey Game Trackers
        </h1>
      </div>
      {renderLeagues(eventsByLeagueName)}
      {/* <Paper className={styles.gameTrackersContainer}>
        {renderLeagueEvents()}
      </Paper> */}
    </div>
  );
};

HockeyGameTracker.propTypes = {
  className: PropTypes.string,
};

export default React.memo(HockeyGameTracker);
