import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import MlbStatsContext from './MlbStatsContext';
import Utils from 'Utils';

const cloudFrontToken = `dklxbc86gvygh`;
// const cloudFrontToken = `d1wignjlnasfdp`; //test

// const BASE_URL = `https://ipl-sportsdata2--test.s3.amazonaws.com`;
const BASE_URL = `https://${cloudFrontToken}.cloudfront.net`;

const fetchTeamLogs = async (season) => {
  const url = new URL(`${BASE_URL}/mlb/${season}/team/all-stats.json`);
  url.searchParams.set('t', Utils.dateToDayString(new Date()));
  const response = await fetch(url);
  return await response.json();
};

const MlbStatsProvider = (props) => {
  const { children, season } = props;

  const [updatedAt, setUpdatedAt] = useState(null);
  const [teamLogs, setTeamLogs] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setTeamLogs(null);
    setIsFetching(true);
    setFetchError(null);
    const loader = async () => {
      try {
        const { data, updateTime } = await fetchTeamLogs(season);
        setTeamLogs(data);
        setUpdatedAt(new Date(updateTime));
      } catch (e) {
        setTeamLogs({});
        setFetchError(`Unable to load data`);
        console.error(`Unable to load data`, e);
      }

      setIsFetching(false);
    };
    loader();
    return () => {
      setTeamLogs(null);
      setIsFetching(false);
    };
  }, [season]);

  const contextValue = {
    fetchError,
    isFetching,
    data: {
      teamLogs,
      updatedAt,
    },
  };

  return <MlbStatsContext.Provider value={contextValue}>{children}</MlbStatsContext.Provider>;
};

MlbStatsProvider.defaultProps = {
  season: 2024,
};
MlbStatsProvider.propTypes = {
  season: PropTypes.any,
};

export default MlbStatsProvider;
