import _ from 'lodash';

const D = new Date().getDate();
const USE_TEST_DATA = false;

const cloudFrontToken = USE_TEST_DATA ?  `d1wignjlnasfdp` : `dklxbc86gvygh`;
const BASE_URL = `https://${cloudFrontToken}.cloudfront.net`;

// const BUCKET_SUFFIX = USE_TEST_DATA ? `--test` : ``;
// const BASE_URL = `https://ipl-sportsdata2${BUCKET_SUFFIX}.s3.amazonaws.com`;
class Stats {
  data = {
    summaryByTeam: {},
    teamLogs: {},
  };

  update = (newData) => {
    this.data = _.merge({}, this.data, newData);
  };

  loadSummaryByTeam = async (season) => {
    const response = await fetch(`${BASE_URL}/mlb/${season}/summary/stats/teams.json?d=${D}`);
    const { data } = await response.json();
    this.update({ summaryByTeam: data });
    return this.data;
  };

  loadTeamLog = async (season, teamCode) => {
    const response = await fetch(`${BASE_URL}/mlb/${season}/team/stats/${teamCode}.json?d=${D}`);
    const { data } = await response.json();
    this.update({ teamLogs: { [teamCode]: data } });
    return this.data;
  };

  loadAllTeamLogs = async (season) => {
    const response = await fetch(`${BASE_URL}/mlb/${season}/team/all-stats.json?d=${D}`);
    const { data } = await response.json();
    this.update({ teamLogs: data });
    return this.data;
  };
}

const instance = new Stats();
export default instance;
