import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// import { FormControl, MenuItem, Select } from '@mui/material';

import styles from './DateChooser.module.scss';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const DateChooser = (props) => {
  const { selectedDate, onChange } = props;
  const dayLabel = selectedDate.toLocaleDateString();

  const onBackClick = () => {
    const newDate = dayjs(selectedDate).add(-1, 'day');
    onChange(newDate.toDate());
  };
  const onForwardClick = () => {
    const newDate = dayjs(selectedDate).add(1, 'day');
    onChange(newDate.toDate());
  };
  return (
    <div className={styles.container}>
      <IconButton onClick={onBackClick}>
        <ArrowBack />
      </IconButton>
      <div className={styles.dayLabel}>{dayLabel}</div>
      <IconButton onClick={onForwardClick}>
        <ArrowForward />
      </IconButton>
    </div>
  );
};

DateChooser.propTypes = {
  selectedDate: PropTypes.object,
  onChange: PropTypes.func,
};

export default DateChooser;
