import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import { CircularProgress, FormControl, MenuItem, Paper, Select } from '@mui/material';

import MlbStatsContext from './MlbStatsContext';
import MlbPppLog from './MlbPppLog';
import MlbDahLog from './MlbDahLog';

import styles from './MlbStats.module.scss';

const SUBVIEWS = {
  // TEAM_SUMMARY: 'team-summary',
  DAH_LOG: 'dah-log',
  PPP_LOG: 'ppp-log',
};
const DEFAULT_SUBVIEW = SUBVIEWS.PPP_LOG;

const getDefaultSubView = (routeParams) => {
  const desiredSubView = routeParams.subview;
  if (!_.isEmpty(desiredSubView)) {
    for (const subView of _.values(SUBVIEWS)) {
      if (subView === desiredSubView) return subView;
    }
  }
  return DEFAULT_SUBVIEW;
};

const MlbStats = (props) => {
  const { className } = props;
  const { data, isFetching, fetchError } = useContext(MlbStatsContext);
  const routeParams = useParams();
  const navigate = useNavigate();
  const [subView, setSubView] = useState(getDefaultSubView(routeParams));

  useEffect(() => {
    if (DEFAULT_SUBVIEW !== subView) {
      navigate(`/mlb/stats/${subView}`);
    } else {
      navigate(`/mlb/stats`);
    }
  }, [subView]);

  let updatedAt = `Updated: ??`;
  let overlay = null;
  let overlayContent = null;

  if (isFetching) {
    overlayContent = (
      <>
        <CircularProgress />
        <div>Loading...</div>
      </>
    );
  }

  if (fetchError) {
    overlayContent = (
      <>
        <div>Unable to load data</div>
      </>
    );
  }

  if (overlayContent) {
    overlay = (
      <div className={cx(styles.overlay)}>
        <Paper className={styles.overlayContent}>{overlayContent}</Paper>
      </div>
    );
  }

  if (data?.updatedAt) {
    updatedAt = `Updated: ${data.updatedAt?.toLocaleString()}`;
  }

  const onSubViewChange = (event) => {
    const newSubView = event?.target?.value;
    if (!newSubView) return;
    setSubView(newSubView);
  };

  let subViewComponent = null;
  switch (subView) {
    case SUBVIEWS.DAH_LOG:
      subViewComponent = <MlbDahLog />;
      break;
    default:
    case SUBVIEWS.PPP_LOG:
      subViewComponent = <MlbPppLog />;
      break;

    // case VIEWS.TEAM_SUMMARY:
    // default:
    //   subViewComponent = <NhlStatsEmptyNetTeamSummary />;
    //   break;
  }

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.header}>
        <FormControl size='small'>
          <Select variant='outlined' id='season-select' classes={{ select: styles.subViewSelect }} value={subView} onChange={onSubViewChange}>
            <MenuItem value={SUBVIEWS.DAH_LOG}>DAH Log</MenuItem>
            <MenuItem value={SUBVIEWS.PPP_LOG}>PPP Log</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Paper className={styles.body} square>
        {subViewComponent}
        {overlay}
      </Paper>
      <div className={styles.footer}>{updatedAt}</div>
    </div>
  );
};

MlbStats.propTypes = {
  className: PropTypes.string,
};

export default MlbStats;
