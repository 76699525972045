import React, { useContext } from 'react';
import cx from 'classnames';
import _ from 'lodash';

import { GAME_SCHEDULE_STATES, GAME_STATE_GROUPS } from 'nhl/constants';
import NhlMatchupContext from 'nhl/stats/NhlMatchupContext';
import MatchupUtil from './MatchupUtil';

import styles from './MatchupStatus.module.scss';

// const MATCHUP_TIME_FORMATTER = new Intl.DateTimeFormat(undefined, { timeStyle: 'short' });

const MatchupStatus = (props) => {
  const { className } = props;
  const { matchup } = useContext(NhlMatchupContext);

  if (_.isEmpty(matchup)) return null;
  const { gameState, periodDescriptor, clock, situation, gameScheduleState } = matchup.data;

  const gameStateGroup = MatchupUtil.getTypeGroup(gameState);
  // const gameStateGroup = GAME_STATE_GROUPS.LIVE;
  // if (!gameStateGroup) return null;

  const renderPrematch = () => {
    const label = MatchupUtil.formatStartTime(matchup.info?.date);
    let subLabel = null;
    if (gameScheduleState !== GAME_SCHEDULE_STATES.OK) {
      subLabel = gameScheduleState;
    }

    return (
      <div key='status-prematch' className={cx(styles.statusChip, styles.statusPrematch)}>
        {label}
        {subLabel}
      </div>
    );
  };

  const renderFinal = () => {
    const periodType = periodDescriptor?.periodType;
    let extraPeriodLabel = ``;
    if (periodType !== 'REG') {
      extraPeriodLabel = ` ${periodDescriptor?.periodType}`;
    }

    return <div key='status-final' className={cx(styles.statusChip, styles.statusFinal)}>{`FINAL${extraPeriodLabel}`}</div>;
  };

  const renderSituation = () => {
    // const situation = {
    //   awayTeam: { strength: 4 },
    //   homeTeam: { strength: 5 },
    // };
    if (_.isEmpty(situation)) return null;
    const awayStrength = situation?.awayTeam?.strength || 5;
    const homeStrength = situation?.homeTeam?.strength || 5;
    if (awayStrength === 5 && homeStrength === 5) return null;

    return (
      <div key='status-situation' className={cx(styles.statusChip, styles.statusSituation)}>
        <div className={styles.strengthNum}>{awayStrength}</div>
        <div className={styles.strengthNumSep}>on</div>
        <div className={styles.strengthNum}>{homeStrength}</div>
      </div>
    );
  };
  const renderLive = () => {
    const period = periodDescriptor?.number;
    const periodType = periodDescriptor?.periodType;

    // const typeGroup = MatchupUtil.getTypeGroup(gameState);
    // if (!typeGroup || typeGroup === GAME_STATE_GROUPS.PREMATCH) return null;
    if (_.isEmpty(clock)) return null;

    const classes = [styles.statusChip, styles.statusLive];
    if (clock.running) {
      classes.push(styles.isRunning);
    }
    if (clock.inIntermission) {
      classes.push(styles.isIntermission);
    }

    let currentPeriodLabel = `P${period}`;
    let timeRemainingLabel = clock.timeRemaining;

    if (periodType !== 'REG') {
      currentPeriodLabel = periodType;
    }

    if (clock.inIntermission) {
      timeRemainingLabel = `(${clock.timeRemaining})`;
      currentPeriodLabel = `I${period}`;
    }

    return (
      <div key='status-live' className={cx(classes)}>
        <div className={styles.periodTime}>
          <div className={cx(styles.periodNum)}>{currentPeriodLabel}</div>
          <div className={cx(styles.periodTimeRemaining)}>{timeRemainingLabel}</div>
        </div>
      </div>
    );
  };

  const content = [];
  switch (gameStateGroup) {
    case GAME_STATE_GROUPS.LIVE:
      content.push(renderLive());
      content.push(renderSituation());
      break;
    case GAME_STATE_GROUPS.ENDED:
      content.push(renderFinal());
      break;
    default:
    case GAME_STATE_GROUPS.PREMATCH:
      content.push(renderPrematch());
      break;
  }

  return <div className={cx(styles.container, className)}>{content}</div>;
};

export default MatchupStatus;
