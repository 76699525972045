import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import NhlMatchupContext from './NhlMatchupContext';

const NhlMatchupProvider = (props) => {
  const { children } = props;
  const [matchup] = useState(props.matchup);
  const [updateTime, setUpdateTime] = useState(new Date());

  useEffect(() => {
    if (!matchup) return;
    matchup.setOnChange(() => {
      setUpdateTime(new Date());
    });
  }, [matchup, setUpdateTime]);

  const contextValue = {
    id: matchup?.id,
    matchup: matchup,
    updateTime,
  };

  return <NhlMatchupContext.Provider value={contextValue}>{children}</NhlMatchupContext.Provider>;
};

NhlMatchupProvider.propTypes = {
  children: PropTypes.node,
};

export default NhlMatchupProvider;
